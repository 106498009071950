/**
 * @generated SignedSource<<0a2645e6044f792f7131e4979d3fc783>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsOverviewTabEnabled_collection$data = {
  readonly metadata: {
    readonly overview: {
      readonly modules: ReadonlyArray<{
        readonly __typename: string;
      }> | null;
    } | null;
  } | null;
  readonly redeemableCampaign: {
    readonly __typename: "RedeemableCampaignType";
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useDropState_data">;
  readonly " $fragmentType": "useIsOverviewTabEnabled_collection";
};
export type useIsOverviewTabEnabled_collection$key = {
  readonly " $data"?: useIsOverviewTabEnabled_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsOverviewTabEnabled_collection">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsOverviewTabEnabled_collection",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDropState_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RedeemableCampaignType",
      "kind": "LinkedField",
      "name": "redeemableCampaign",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionMetadataType",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OverviewMetadatatype",
          "kind": "LinkedField",
          "name": "overview",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "modules",
              "plural": true,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "079a8b7303caab06b68cb60a674bbe0d";

export default node;
