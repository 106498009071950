import React, { useState } from "react"
import { ClassValue } from "clsx"
import { useFragment } from "react-relay"
import { usePrevious } from "react-use"
import { AccountOrCollectionInfo } from "@/components/layout/AccountOrCollectionPage/components/AccountOrCollectionInfo"
import { EMPTY_PRICE_DISPLAY } from "@/constants"
import { CollectionAssetSearchState } from "@/features/collections/components/CollectionPage/components/CollectionAssetSearch"
import { useCollectionListingStatsEvent } from "@/features/live-updates/hooks/useCollectionListingStatsEvent"
import { useCollectionStatsEvent } from "@/features/live-updates/hooks/useCollectionStatsEvent"
import { usePollingQuery } from "@/hooks/usePollingQuery"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionStatsInner_data$key } from "@/lib/graphql/__generated__/CollectionStatsInner_data.graphql"
import { CollectionStatsInnerPoll_data$key } from "@/lib/graphql/__generated__/CollectionStatsInnerPoll_data.graphql"
import {
  CollectionStatsInnerPollingQuery,
  CollectionStatsInnerPollingQuery$data,
} from "@/lib/graphql/__generated__/CollectionStatsInnerPollingQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getCollectionStats, getCollectionUrl } from "@/lib/helpers/collection"
import { display } from "@/lib/helpers/numberUtils"
import { stringifyQueryParams } from "@/lib/helpers/urls"
import { CollectionStatsSkeleton } from "../CollectionStatsSkeleton"

const POLLING_DELAY = 5_000 // 5 seconds

type CollectionStatsInnerProps = {
  dataKey: CollectionStatsInner_data$key | null
  slug: string
  labelClassName?: ClassValue
  numberClassName?: ClassValue
  disablePolling?: boolean
}

type CollectionStatsInnerPollProps = {
  dataKey: CollectionStatsInner_data$key | null
  poll: CollectionStatsInnerPoll_data$key | null
  labelClassName?: ClassValue
  numberClassName?: ClassValue
}

type searchParams = Partial<CollectionAssetSearchState>

const refreshAssetView = (
  prevValue: string | null,
  newValue: string | null,
  setter: (value: React.SetStateAction<string | null>) => void,
) => {
  if (prevValue !== newValue) {
    setTimeout(() => setter(newValue), 3000)
  }
}

const CollectionStatsInnerPoll = ({
  dataKey,
  poll,
  labelClassName,
  numberClassName,
}: CollectionStatsInnerPollProps) => {
  const t = useTranslate("collection")
  const router = useRouter()
  const data = useFragment(
    graphql`
      fragment CollectionStatsInner_data on CollectionType {
        isCollectionOffersEnabled
        description
        slug
        name
        metadata {
          custom {
            isMockDrop
          }
        }
        dropv2 {
          landingPageReady
        }
        ...collection_url
        ...collection_poll_stats
        ...collection_live_stats
        ...collection_live_listing_stats
      }
    `,
    dataKey,
  )

  const pollData = useFragment(
    graphql`
      fragment CollectionStatsInnerPoll_data on CollectionType {
        ...collection_poll_stats
      }
    `,
    poll,
  )

  const [ownersState, setOwnersState] = useState<string | null>(null)
  const [floorState, setFloorState] = useState<string | null>(null)
  const [offerState, setOfferState] = useState<string | null>(null)
  const [conciseVolumeState, setConciseVolumeState] = useState<string | null>(
    null,
  )
  const previousFloor = usePrevious(floorState)
  const previousOffer = usePrevious(offerState)
  const previousVolume = usePrevious(conciseVolumeState)
  const previousOwners = usePrevious(ownersState)

  if (!data) {
    return <CollectionStatsSkeleton />
  }

  const { isCollectionOffersEnabled } = data

  const {
    floor,
    floorSymbol,
    volumeSymbol,
    offer,
    offerSymbol,
    listedCount,
    listedPercentage,
    uniqueOwnersCount,
    uniqueOwnersPercentage,
    totalVolume,
    conciseVolume,
  } = getCollectionStats(pollData ?? data, data, data)

  refreshAssetView(ownersState, uniqueOwnersCount.toString(), setOwnersState)
  refreshAssetView(floorState, floor, setFloorState)
  refreshAssetView(offerState, offer, setOfferState)
  refreshAssetView(conciseVolumeState, conciseVolume, setConciseVolumeState)

  const currentQueryParams = router.queryParams
  const floorQueryParams = stringifyQueryParams({
    search: {
      ...(currentQueryParams.search as searchParams),
      sortAscending: true,
      sortBy: "UNIT_PRICE",
      toggles: ["BUY_NOW"],
    },
  })
  const listingsQueryParams = stringifyQueryParams({
    search: {
      eventTypes: ["AUCTION_CREATED"],
    },
  })
  const collectionUrl = getCollectionUrl(data)
  const totalVolumeToolTip = `${totalVolume} ${volumeSymbol}`

  const stats = [
    {
      label: t("collectionInfo.details.totalVolume", "Total volume"),
      prevNumber: previousVolume,
      number: conciseVolume,
      symbol: volumeSymbol,
      tooltip: totalVolume !== conciseVolume ? totalVolumeToolTip : undefined,
      url: `${collectionUrl}/activity`,
      testId: "collection-stats-volume",
    },
    {
      label: t("collectionInfo.details.floorPrice", "Floor price"),
      prevNumber: previousFloor,
      number: floor,
      symbol: floor === EMPTY_PRICE_DISPLAY ? null : floorSymbol,
      url: `${collectionUrl}${floorQueryParams}`,
      testId: "collection-stats-floor-price",
    },
    ...(isCollectionOffersEnabled
      ? [
          {
            label: t("collectionInfo.details.bestOffer", "Best offer"),
            prevNumber: previousOffer,
            number: offer,
            symbol: offer === EMPTY_PRICE_DISPLAY ? null : offerSymbol,
            url: `${getCollectionUrl(data)}/offers`,
            testId: "collection-stats-best-offer",
          },
        ]
      : []),
    {
      label: t("collectionInfo.details.listed", "Listed"),
      number: listedPercentage,
      tooltip: t(
        "collectionInfo.details.listedCount",
        {
          0: "{{displayCount}} listed",
          one: "{{displayCount}} listed",
          other: "{{displayCount}} listed",
        },
        {
          count: listedCount,
          displayCount: display(listedCount),
        },
        { forceString: true },
      ),
      url: `${collectionUrl}/activity${listingsQueryParams}`,
      testId: "collection-stats-listed",
    },
    {
      label: `${t(
        "collectionInfo.details.ownersCount6",
        {
          0: "Owners",
          one: "Owner",
          other: "Owners",
        },
        {
          count: uniqueOwnersCount,
        },
        { forceString: true },
      )}${
        parseFloat(uniqueOwnersPercentage) > 0
          ? ` (${t("collectionInfo.details.uniquePercentage", "Unique")})`
          : ""
      }`,
      prevNumber: previousOwners,
      number: `${uniqueOwnersCount > 0 ? display(uniqueOwnersCount) : "--"}${
        parseFloat(uniqueOwnersPercentage) > 0
          ? ` (${uniqueOwnersPercentage})`
          : ""
      }`,
      testId: "collection-stats-owners",
    },
  ]

  return (
    <AccountOrCollectionInfo
      details={stats}
      labelClassName={labelClassName}
      numberClassName={numberClassName}
    />
  )
}

export const CollectionStatsInner = ({
  dataKey,
  slug,
  labelClassName,
  numberClassName,
  disablePolling,
}: CollectionStatsInnerProps) => {
  useCollectionStatsEvent({ slug })
  useCollectionListingStatsEvent({ slug })

  const [pollData, setPollData] =
    useState<CollectionStatsInnerPollingQuery$data | null>()

  usePollingQuery<CollectionStatsInnerPollingQuery>(
    graphql`
      query CollectionStatsInnerPollingQuery($collection: CollectionSlug!) {
        collection(collection: $collection) {
          ...CollectionStatsInnerPoll_data
        }
      }
    `,
    {
      collection: slug,
    },
    {
      delay: POLLING_DELAY,
      skipOnHidden: true,
      skip: disablePolling,
      onPoll: data => setPollData(data),
    },
  )

  return (
    <CollectionStatsInnerPoll
      dataKey={dataKey}
      labelClassName={labelClassName}
      numberClassName={numberClassName}
      poll={pollData?.collection ?? null}
    />
  )
}
