import React, { useEffect, useMemo, useState } from "react"
import { useIsLessThanMd, useIsLessThanXXl } from "@opensea/ui-kit"
import { differenceInDays, parseJSON } from "date-fns"
import { useFragment } from "react-relay"
import { createContext, useContextSelector } from "use-context-selector"
import { AccountLinkV2 } from "@/components/accounts/AccountLinkV2"
import { Link } from "@/components/common/Link"
import { StageStartCountdown } from "@/features/primary-drops/components/StageStartCountdown"
import { useDropStages } from "@/features/primary-drops/hooks/useDropStages"
import {
  DROP_STATES,
  useDropState,
} from "@/features/primary-drops/hooks/useDropState"
import { BaseCollectionHeroBanner_data$key } from "@/lib/graphql/__generated__/BaseCollectionHeroBanner_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { isSafelisted } from "@/lib/helpers/verification"
import { CollectionHeroBanner } from "../CollectionHeroBanner"
import {
  CollectionHeroBannerLayout,
  CollectionHeroBannerLayoutProps,
} from "../CollectionHeroBannerLayout"

type HeroMedia = {
  mediaType?: string
  thumbnailUrl?: string
  playbackId?: string
  videoUrl?: string
  imageUrl?: string
}

type BaseCollectionHeroBannerProps = {
  name: string
  description?: string
  desktopHeroMedia?: HeroMedia
  mobileHeroMedia?: HeroMedia
  legacyBannerImageUrl?: string | null
  profileImageUrl?: string | null
  dataKey: BaseCollectionHeroBanner_data$key | null
  componentOverrides?: Partial<CollectionHeroBannerLayoutProps>
  isPreview?: boolean
}

type CollectionHeroBannerContextProps = {
  showFullOverlay: boolean
  setShowFullOverlay: (showFullOverlay: boolean) => void
}

export const CollectionHeroBannerContext =
  createContext<CollectionHeroBannerContextProps>({
    showFullOverlay: false,
    setShowFullOverlay: () => {},
  })

const CollectionHeroBannerComponent = ({
  name,
  description,
  desktopHeroMedia,
  mobileHeroMedia,
  legacyBannerImageUrl,
  profileImageUrl,
  dataKey,
  componentOverrides,
  isPreview,
}: BaseCollectionHeroBannerProps) => {
  // The data requested in this fragment shouldn't be overwritable by WYSIWYG
  const data = useFragment<BaseCollectionHeroBanner_data$key>(
    graphql`
      fragment BaseCollectionHeroBanner_data on CollectionType {
        name
        verificationStatus
        owner {
          ...AccountLinkV2_account
        }
        dropv2 {
          stages {
            endTime
            ...useDropStages
            ...StageStartCountdown_stage
          }
        }
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...CollectionHeroInfo_data
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...CollectionHeroStats_data
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...CollectionBannerActionBar_data
        ...useDropState_data
        ...collection_url
      }
    `,
    dataKey,
  )

  const [showFullOverlay, setShowFullOverlay] = useContextSelector(
    CollectionHeroBannerContext,
    v => [v.showFullOverlay, v.setShowFullOverlay],
  )
  const showLegacyMedia = !desktopHeroMedia && !mobileHeroMedia
  const isLessThanMd = useIsLessThanMd()
  const isLessThanXXl = useIsLessThanXXl()
  const showDescriptionOverHero = !showLegacyMedia && !isLessThanXXl
  const dropState = useDropState(data)
  const showAccountLink = data?.owner && isSafelisted(data.verificationStatus)
  const collectionOverviewUrl = data
    ? `${getCollectionUrl(data)}/overview`
    : null

  useEffect(() => {
    // Everytime showDescriptionOverHero changes, the component will be reset, so we need to reset the overlay
    setShowFullOverlay(false)
  }, [setShowFullOverlay, showDescriptionOverHero])

  // Memoize layout components with overrides
  const accountLink = useMemo(
    () =>
      showAccountLink
        ? componentOverrides?.accountLink ?? (
            <AccountLinkV2 account={data.owner}>
              <AccountLinkV2.Name
                className="text-white opacity-80"
                size={isLessThanMd ? "small" : "medium"}
              />
              <AccountLinkV2.Badge />
            </AccountLinkV2>
          )
        : null,
    [
      componentOverrides?.accountLink,
      data?.owner,
      isLessThanMd,
      showAccountLink,
    ],
  )

  const actionBar = useMemo(
    () =>
      componentOverrides?.actionBar ?? {
        on: {
          media: (
            <CollectionHeroBanner.ActionBar
              className="text-white"
              dataKey={data}
            />
          ),
          base: <CollectionHeroBanner.ActionBar dataKey={data} />,
        },
      },
    [componentOverrides?.actionBar, data],
  )

  const collectionInfo = useMemo(
    () =>
      componentOverrides?.info ?? {
        on: {
          base: (
            <CollectionHeroBanner.Info
              dataKey={data}
              description={description}
              metadataClassName="text-secondary"
            />
          ),
          media: (
            <CollectionHeroBanner.Info
              dataKey={data}
              description={description}
              descriptionClassName="text-white"
              maxHeight={200}
              metadataClassName="text-white opacity-80"
              onSeeMore={() => setShowFullOverlay(!showFullOverlay)}
            />
          ),
        },
      },
    [
      componentOverrides?.info,
      data,
      description,
      setShowFullOverlay,
      showFullOverlay,
    ],
  )

  const media = useMemo(() => {
    return (
      componentOverrides?.media ?? (
        <CollectionHeroBanner.Media
          desktopHeroMedia={desktopHeroMedia}
          legacyBannerImageUrl={legacyBannerImageUrl}
          mobileHeroMedia={mobileHeroMedia}
          profileImageUrl={profileImageUrl}
          showOverlay={showFullOverlay && showDescriptionOverHero}
        />
      )
    )
  }, [
    componentOverrides?.media,
    desktopHeroMedia,
    legacyBannerImageUrl,
    mobileHeroMedia,
    profileImageUrl,
    showDescriptionOverHero,
    showFullOverlay,
  ])

  const mintingNow = dropState == DROP_STATES.ACTIVE
  const mintEnded =
    dropState == DROP_STATES.ENDED || dropState == DROP_STATES.MINTED_OUT
  const { ctaStage } = useDropStages(data?.dropv2?.stages ?? [])
  const stageEndAt = ctaStage?.endTime ? parseJSON(ctaStage.endTime) : null
  const daysSinceEnd = stageEndAt ? differenceInDays(new Date(), stageEndAt) : 0
  const mintingIndicator = useMemo(() => {
    const indicator =
      componentOverrides?.mintingIndicator ??
      (mintingNow ? (
        <CollectionHeroBanner.MintingIndicator />
      ) : mintEnded && daysSinceEnd < 1 ? (
        <CollectionHeroBanner.MintEndedIndicator />
      ) : null)

    return collectionOverviewUrl && indicator ? (
      <Link href={collectionOverviewUrl}>{indicator}</Link>
    ) : (
      indicator
    )
  }, [
    componentOverrides?.mintingIndicator,
    mintingNow,
    collectionOverviewUrl,
    mintEnded,
    daysSinceEnd,
  ])

  const dropCountdown = useMemo(
    () =>
      dropState === DROP_STATES.UPCOMING && (
        <StageStartCountdown dataKey={ctaStage} translucent />
      ),
    [ctaStage, dropState],
  )

  const stats = useMemo(
    () =>
      componentOverrides?.stats ?? {
        on: {
          base: <CollectionHeroBanner.Stats dataKey={data} />,
          media: (
            <CollectionHeroBanner.Stats
              dataKey={data}
              labelClassName="text-white opacity-80"
              numberClassName="text-white hover:text-white active:text-white hover:opacity-70 active:opacity-60"
            />
          ),
        },
      },
    [componentOverrides?.stats, data],
  )

  const thumbnail = useMemo(
    () =>
      componentOverrides?.thumbnail ?? (
        <CollectionHeroBanner.Thumbnail altText="" imageUrl={profileImageUrl} />
      ),
    [componentOverrides?.thumbnail, profileImageUrl],
  )

  const title = useMemo(
    () =>
      componentOverrides?.title ?? (
        <CollectionHeroBanner.Title
          title={name}
          verificationStatus={data?.verificationStatus}
        />
      ),
    [componentOverrides?.title, data?.verificationStatus, name],
  )

  return (
    <CollectionHeroBannerLayout
      accountLink={accountLink}
      actionBar={actionBar}
      dropCountdown={dropCountdown}
      info={collectionInfo}
      isPreview={isPreview}
      media={media}
      mintingIndicator={mintingIndicator}
      showLegacyMedia={showLegacyMedia}
      stats={stats}
      thumbnail={thumbnail}
      title={title}
    />
  )
}

export const BaseCollectionHeroBanner = (
  props: BaseCollectionHeroBannerProps,
) => {
  const [showFullOverlay, setShowFullOverlay] = useState(false)

  return (
    <CollectionHeroBannerContext.Provider
      value={{ showFullOverlay, setShowFullOverlay }}
    >
      <CollectionHeroBannerComponent {...props} />
    </CollectionHeroBannerContext.Provider>
  )
}
