import React from "react"
import { UnstyledButton } from "@opensea/ui-kit"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { CollectionStatusModal } from "@/components/modals/CollectionStatusModal.react"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { verificationStatusHasBadge } from "@/lib/helpers/verification"

export const VerificationStatusBadge = ({
  verificationStatus,
}: {
  verificationStatus: "VERIFIED" | "MINTABLE"
}) => {
  if (!verificationStatusHasBadge(verificationStatus)) {
    return null
  }

  return (
    <Modal
      trigger={open => (
        <Block display="inline-flex" marginBottom="4px" verticalAlign="middle">
          <UnstyledButton onClick={open}>
            <VerificationIcon
              size="medium"
              verificationStatus={verificationStatus as "VERIFIED" | "MINTABLE"}
            />
          </UnstyledButton>
        </Block>
      )}
    >
      <CollectionStatusModal verificationStatus={verificationStatus} />
    </Modal>
  )
}
