import React, { ComponentProps, createContext, useContext } from "react"
import {
  TextBodyProps,
  TextDisplayProps,
  TextHeadingProps,
  Text,
  FlexProps,
  classNames,
  Flex,
} from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { AccountTooltip } from "@/components/accounts/AccountTooltip.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Tooltip, TooltipPlacement } from "@/design-system/Tooltip"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { AccountTab } from "@/features/account/types"
import {
  AccountLinkV2_account$data,
  AccountLinkV2_account$key,
} from "@/lib/graphql/__generated__/AccountLinkV2_account.graphql"
import { getAccountUrl } from "@/lib/helpers/accounts"
import {
  formatAddress,
  isNullAddress,
  truncateAddress,
} from "@/lib/helpers/address"
import { truncateText } from "@/lib/helpers/stringUtils"
import AccountBadge from "../../common/AccountBadge.react"
import { Link } from "../../common/Link"
import { ProfileImage } from "../../common/ProfileImage.react"

const MAX_USERNAME_LEN = 21

type AccountLinkContextType = {
  account: AccountLinkV2_account$data | undefined
  // TBD on if we should remove this prop entirely and rename this component to AccountLockup or something.
  // Only here as the old AccountLink supports it
  isLink?: boolean
  displayText: string
  linkToTab?: AccountTab
  disabled?: boolean
}

const AccountLinkContext = createContext<AccountLinkContextType>({
  account: undefined,
  isLink: false,
  displayText: "",
  linkToTab: undefined,
  disabled: false,
})

type Props = Pick<AccountLinkContextType, "isLink" | "linkToTab"> & {
  account: AccountLinkV2_account$key
  children: React.ReactNode
} & FlexProps

const AccountLinkBase = ({
  account: accountKey,
  children,
  className,
  isLink = true,
  linkToTab,
  ...flexProps
}: Props) => {
  const { isActiveAccount } = useWallet()

  const account = useFragment(
    graphql`
      fragment AccountLinkV2_account on AccountType {
        address
        config
        isCompromised
        user {
          publicUsername
        }
        displayName
        ...ProfileImage_data
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...wallet_accountKey
        ...accounts_url
      }
    `,
    accountKey,
  )

  const truncatedPublicUsername = account.user?.publicUsername
    ? truncateText(account.user.publicUsername, MAX_USERNAME_LEN)
    : account.displayName ?? undefined

  const displayText = isActiveAccount(account)
    ? "you"
    : truncatedPublicUsername || formatAddress(account.address)

  const disabled = isNullAddress(account.address)

  return (
    <AccountLinkContext.Provider
      value={{ account, isLink, displayText, linkToTab, disabled }}
    >
      <Flex {...flexProps} className={classNames("items-center", className)}>
        {children}
      </Flex>
    </AccountLinkContext.Provider>
  )
}

type AccountLinkAvatarProps = {
  size?: number
}

const WrapLinkIfNeeded = ({ content }: { content: React.ReactNode }) => {
  const { account, isLink, disabled, linkToTab } =
    useContext(AccountLinkContext)

  if (!account) {
    return null
  }

  if (isLink) {
    return (
      <Link
        disabled={disabled}
        href={getAccountUrl(account, { tab: linkToTab })}
      >
        {content}
      </Link>
    )
  }

  return content
}

const AccountLinkAvatar = ({ size = 22 }: AccountLinkAvatarProps) => {
  const { account, displayText } = useContext(AccountLinkContext)

  if (!account) {
    return null
  }

  return (
    <WrapLinkIfNeeded
      content={
        <Flex className="mr-3">
          <Tooltip content={displayText}>
            <ProfileImage data={account} display="flex" size={size} />
          </Tooltip>
        </Flex>
      }
    />
  )
}

type AccountLinkNameProps = {
  tooltipPlacement?: TooltipPlacement
  colorVariant?: "link" | "text"
} & (
  | ({ textVariant: "display" } & TextDisplayProps)
  | ({ textVariant: "heading" } & TextHeadingProps)
  | ({ textVariant?: "body" } & TextBodyProps)
)

const AccountLinkName = ({
  tooltipPlacement,
  colorVariant = "link",
  textVariant = "body",
  ...textProps
}: AccountLinkNameProps) => {
  const { account, displayText } = useContext(AccountLinkContext)

  if (!account) {
    return null
  }

  const TextComponent = ({ children }: { children: React.ReactNode }) => {
    if (textVariant === "body") {
      return <Text.Body {...(textProps as TextBodyProps)}>{children}</Text.Body>
    }

    if (textVariant === "heading") {
      return (
        <Text.Heading {...(textProps as TextHeadingProps)}>
          {children}
        </Text.Heading>
      )
    }

    return (
      <Text.Display {...(textProps as TextDisplayProps)}>
        {children}
      </Text.Display>
    )
  }

  return (
    <AccountTooltip address={account.address} placement={tooltipPlacement}>
      <WrapLinkIfNeeded
        content={
          <TextComponent>
            <NameText $colorVariant={colorVariant}>{displayText}</NameText>
          </TextComponent>
        }
      />
    </AccountTooltip>
  )
}

const AccountLinkBadge = (
  props: Omit<ComponentProps<typeof AccountBadge>, "config" | "isCompromised">,
) => {
  const { account } = useContext(AccountLinkContext)

  if (!account) {
    return null
  }

  return (
    <AccountBadge
      config={account.config}
      isCompromised={account.isCompromised}
      {...props}
    />
  )
}

const AccountLinkWalletAddress = (props: TextBodyProps) => {
  const { account } = useContext(AccountLinkContext)

  if (!account) {
    return null
  }

  return (
    <Text.Body className="text-secondary" {...props}>
      {truncateAddress(account.address)}
    </Text.Body>
  )
}

const NameText = styled(Block)<{ $colorVariant: "link" | "text" }>`
  ${props =>
    props.$colorVariant === "link" ? "inherit" : interactiveStylesPrimary};
`

export const AccountLinkV2 = Object.assign(AccountLinkBase, {
  Avatar: AccountLinkAvatar,
  Name: AccountLinkName,
  WalletAddress: AccountLinkWalletAddress,
  Badge: AccountLinkBadge,
})
