import React from "react"
import { Container, Flex } from "@opensea/ui-kit"
import { NAV_HEIGHT_PX } from "@/styles/variables"

type Props = {
  banner?: React.ReactNode | null
  inspiredBy?: React.ReactNode
  tabs?: React.ReactNode
  content: React.ReactNode
  footer?: React.ReactNode
  isPreview?: boolean
}

export function CollectionPageLayoutV2({
  content,
  banner,
  inspiredBy,
  tabs,
  footer,
  isPreview = false,
}: Props) {
  return (
    <Flex
      className="w-full flex-col"
      style={{ marginTop: -(isPreview ? 0 : NAV_HEIGHT_PX) }}
    >
      {banner}
      <div className="relative">
        {inspiredBy && <Container>{inspiredBy}</Container>}
        {tabs}
        {content}
      </div>
      {footer}
    </Flex>
  )
}
