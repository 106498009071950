import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { useTranslate } from "@/hooks/useTranslate"

export const CollectionHeroMintingIndicator = () => {
  const t = useTranslate("collection")
  return (
    <Flex className="items-center rounded-lg bg-green-1/[0.16]  px-2 py-1.5">
      <div className="mr-2 h-2.5 w-2.5 rounded-full bg-green-1" />
      <Text.Body
        className="whitespace-nowrap text-green-1"
        size="small"
        weight="semibold"
      >
        {t("banner.minting", "Minting")}
      </Text.Body>
    </Flex>
  )
}
