import React from "react"
import { CollectionBannerActionBar } from "./components/CollectionBannerActionBar"
import { CollectionBannerThumbImage } from "./components/CollectionBannerThumbImage"
import { CollectionHeroBannerMedia } from "./components/CollectionHeroBannerMedia"
import { CollectionHeroBannerTitle } from "./components/CollectionHeroBannerTitle"
import { CollectionHeroInfo } from "./components/CollectionHeroInfo"
import { CollectionHeroMintEndedIndicator } from "./components/CollectionHeroMintEndedIndicator"
import { CollectionHeroMintingIndicator } from "./components/CollectionHeroMintingIndicator"
import { CollectionHeroStats } from "./components/CollectionHeroStats"

const CollectionHeroBannerBase = ({
  children,
}: {
  children: React.ReactNode
}) => {
  // Leaving this here for now since we may put some editing specific logic here. If not, will remove.
  return <>{children} </>
}

export const CollectionHeroBanner = Object.assign(CollectionHeroBannerBase, {
  ActionBar: CollectionBannerActionBar,
  Title: CollectionHeroBannerTitle,
  Info: CollectionHeroInfo,
  Media: CollectionHeroBannerMedia,
  MintingIndicator: CollectionHeroMintingIndicator,
  MintEndedIndicator: CollectionHeroMintEndedIndicator,
  Stats: CollectionHeroStats,
  Thumbnail: CollectionBannerThumbImage,
})
