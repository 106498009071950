/**
 * @generated SignedSource<<ca3fb2112d65ac1421213928a3998fce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionHeroInfo_data$data = {
  readonly description: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionDescriptionMetadata_data">;
  readonly " $fragmentType": "CollectionHeroInfo_data";
};
export type CollectionHeroInfo_data$key = {
  readonly " $data"?: CollectionHeroInfo_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionHeroInfo_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionHeroInfo_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionDescriptionMetadata_data"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "2ad8ee78e0484b379b9dbc985ea71a62";

export default node;
