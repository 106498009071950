import React, { ReactNode } from "react"
import { Media, Text, InlineFlex, classNames, Flex } from "@opensea/ui-kit"
import { ClassValue } from "clsx"
import { format } from "date-fns"
import { graphql, useFragment } from "react-relay"
import { Link } from "@/components/common/Link"
import { SHORT_MONTH_YEAR_FORMAT_STRING } from "@/constants/datetime"
import { Block } from "@/design-system/Block"
import { LivePulse } from "@/design-system/LivePulse"
import { LIVE_PULSE_VARIANT } from "@/design-system/LivePulse/LivePulse.react"
import { useCategoryNameGetter } from "@/features/categorization/collection-edit/categoryUtils"
import {
  DROP_STATES,
  useDropState,
} from "@/features/primary-drops/hooks/useDropState"
import { isDropOpenEdition } from "@/features/primary-drops/utils/dropTypeTests"
import { useIsMerchandisingViewEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionDescriptionMetadata_data$key } from "@/lib/graphql/__generated__/CollectionDescriptionMetadata_data.graphql"
import { isSolana } from "@/lib/helpers/chainUtils"
import { getCollectionOverviewUrl } from "@/lib/helpers/collection"
import {
  bn,
  display,
  MIN_DISPLAY_FORMAT,
  shortSymbolDisplay,
} from "@/lib/helpers/numberUtils"

type CollectionDescriptionMetadataProps = {
  dataKey: CollectionDescriptionMetadata_data$key
  variant?: "default" | "compact"
  className?: ClassValue
}

export const CollectionDescriptionMetadata = ({
  dataKey,
  variant = "default",
  className,
}: CollectionDescriptionMetadataProps) => {
  const t = useTranslate("collections")
  const data = useFragment(
    graphql`
      fragment CollectionDescriptionMetadata_data on CollectionType {
        ...collection_url
        metadata {
          custom {
            isMockDrop
            hideProgressBar
          }
        }
        statsV2 {
          hasFungibles
          totalSupply
          totalQuantity
        }
        ...useDropState_data
        dropv2 {
          __typename
          ... on Drop721LimitedEditionType {
            totalItemCount
          }
          ... on Drop1155LimitedEditionType {
            totalItemCount
          }
          mintedItemCount
        }
        category {
          slug
        }
        createdDate
        totalCreatorFeeBasisPoints
        defaultChain {
          displayName
          identifier
        }
      }
    `,
    dataKey,
  )
  const {
    createdDate: createdDateString,
    statsV2,
    totalCreatorFeeBasisPoints,
    defaultChain,
    dropv2: drop,
    category,
  } = data

  const createdDate = new Date(createdDateString)
  const categoryNameGetter = useCategoryNameGetter()
  const categoryName = category?.slug ? categoryNameGetter(category.slug) : ""
  const isOpenEdition = isDropOpenEdition(drop?.__typename ?? "")
  const isMerchandisingEnabled = useIsMerchandisingViewEnabled()

  const totalSupply = shortSymbolDisplay(bn(statsV2.totalSupply), {
    formatDisplay: true,
    shortenBillion: true,
    threshold: MIN_DISPLAY_FORMAT,
    noDecimalWholeNumber: true,
  })
  const totalQuantity = shortSymbolDisplay(bn(statsV2.totalQuantity), {
    formatDisplay: true,
    shortenBillion: true,
    threshold: MIN_DISPLAY_FORMAT,
    noDecimalWholeNumber: true,
  })
  const isCompactVariant = variant === "compact"

  const uniqueItemsContent = t(
    "collections.descriptionMetadata.items.unique",
    {
      "0": "Unique items {{itemCount}}",
      one: "Unique item {{itemCount}}",
      other: "Unique items {{itemCount}}",
    },
    {
      count: statsV2.totalSupply,
      itemCount: <DataText isBolded>{totalSupply}</DataText>,
    },
  )

  let itemsContent = t(
    "collections.descriptionMetadata.items.default",
    {
      "0": "Items {{itemCount}}",
      one: "Item {{itemCount}}",
      other: "Items {{itemCount}}",
    },
    {
      count: Number(statsV2.totalQuantity),
      itemCount: <DataText isBolded>{totalQuantity}</DataText>,
    },
  )

  if (statsV2.hasFungibles) {
    itemsContent = t(
      "collections.descriptionMetadata.items.erc1155",
      {
        "0": "Total items {{itemCount}}",
        one: "Total item {{itemCount}}",
        other: "Total items {{itemCount}}",
      },
      {
        count: Number(statsV2.totalQuantity),
        itemCount: <DataText isBolded>{totalQuantity}</DataText>,
      },
    )
  }

  const dropState = useDropState(data)
  const mintingNow = dropState == DROP_STATES.ACTIVE
  let mintingContent = null
  const isMockDrop = data.metadata?.custom?.isMockDrop
  if (drop?.mintedItemCount && mintingNow && !isMockDrop && !isCompactVariant) {
    mintingContent = (
      <Link href={getCollectionOverviewUrl(data)} variant="subtle">
        <InlineFlex className="items-center">
          <LivePulse variant={LIVE_PULSE_VARIANT.LIVE} />
          <DataText isBolded>
            {t("collectionInfo.details.mintingNow", "Minting now")}
          </DataText>
        </InlineFlex>
      </Link>
    )
    if (!data.metadata?.custom?.hideProgressBar) {
      if (isOpenEdition) {
        itemsContent = t(
          "collectionInfo.details.openEditionItems",
          {
            0: "{{mintedItemCount}} items minted",
            one: "{{mintedItemCount}} item minted",
            other: "{{mintedItemCount}} items minted",
          },
          {
            count: drop.mintedItemCount,
            mintedItemCount: drop.mintedItemCount.toLocaleString(),
          },
        )
      } else if (drop.totalItemCount) {
        itemsContent = t(
          "collectionInfo.details.mintingNowItems",
          "Items {{minted}} of {{itemCount}}",
          {
            minted: (
              <DataText isBolded>{display(drop.mintedItemCount)}</DataText>
            ),
            itemCount: (
              <DataText isBolded>{display(drop.totalItemCount)}</DataText>
            ),
          },
        )
      }
    }
  }

  const createdDateContent = t(
    "collections.descriptionMetadata.created",
    "Created {{createdDate}}",
    {
      createdDate: (
        <DataText isBolded>
          {format(createdDate, SHORT_MONTH_YEAR_FORMAT_STRING)}
        </DataText>
      ),
    },
  )

  const creatorFeeContent = t(
    "collections.descriptionMetadata.creatorEarnings",
    "Creator earnings {{creatorFeePercentage}}",
    {
      creatorFeePercentage: (
        <DataText isBolded>{totalCreatorFeeBasisPoints / 100}%</DataText>
      ),
    },
  )

  const linkClassname = "hover:opacity-70 active:opacity-60"

  const collectionChainContent = t(
    "collections.descriptionMetadata.chain",
    "Chain {{chainName}}",
    {
      chainName: (
        <Link
          className={linkClassname}
          href={`/rankings?chain=${defaultChain.identifier.toLowerCase()}`}
          variant="unstyled"
        >
          <DataText isBolded>{defaultChain.displayName}</DataText>
        </Link>
      ),
    },
  )

  const categoryContent = categoryName
    ? t("collections.descriptionMetadata.category", "Category {{category}}", {
        category: (
          <Link
            className={linkClassname}
            href={`/category/${category?.slug}`}
            variant="unstyled"
          >
            <DataText isBolded>{categoryName}</DataText>
          </Link>
        ),
      })
    : null

  const content = (
    <>
      {mintingContent && (
        <>
          {mintingContent}
          &nbsp;&nbsp;·&nbsp;{" "}
        </>
      )}
      {statsV2.hasFungibles && (
        <>
          <Block
            as="span"
            className="whitespace-pre"
            data-testid="collection-description-metadata-unique-items"
          >
            <DataText>{uniqueItemsContent}</DataText>
          </Block>
          &nbsp;&nbsp;·&nbsp;{" "}
        </>
      )}
      <Block
        as="span"
        className="whitespace-pre"
        data-testid="collection-description-metadata-items"
      >
        <DataText>{itemsContent}</DataText>
      </Block>
      &nbsp;&nbsp;·&nbsp;{" "}
      <Block
        as="span"
        className="whitespace-pre"
        data-testid="collection-description-metadata-created-date"
      >
        <DataText>{createdDateContent}</DataText>
      </Block>
      {/* Creator fees on Solana sometimes vary by asset and we don't currently
          store creator fees at the collection level */}
      {!isSolana(defaultChain.identifier) && (
        <>
          &nbsp;&nbsp;·&nbsp;{" "}
          <Block
            as="span"
            className="whitespace-pre"
            data-testid="collection-description-metadata-creator-fee"
          >
            <DataText>{creatorFeeContent}</DataText>
          </Block>
        </>
      )}
      <>
        &nbsp;&nbsp;·&nbsp;{" "}
        <InlineFlex
          className="whitespace-pre"
          data-testid="collection-description-metadata-chain"
        >
          <DataText>{collectionChainContent}</DataText>
        </InlineFlex>
      </>
      {!isCompactVariant && categoryContent && (
        <>
          &nbsp;&nbsp;·&nbsp;{" "}
          <InlineFlex
            className="whitespace-pre"
            data-testid="collection-description-metadata-category"
          >
            <DataText>{categoryContent}</DataText>
          </InlineFlex>
        </>
      )}
    </>
  )

  const largeContent = <Media greaterThanOrEqual="sm">{content}</Media>

  const textContent = (
    <>
      <Media lessThan="sm">
        {mintingContent && (
          <Flex className="my-5 flex-wrap">{mintingContent}</Flex>
        )}
        <Flex className="flex-wrap">
          <Flex
            className="w-1/2 whitespace-pre"
            data-testid="collection-description-metadata-items"
          >
            <DataText>{itemsContent}</DataText>
          </Flex>
          <Flex
            className="w-1/2 whitespace-pre"
            data-testid="collection-description-metadata-created-date"
          >
            <DataText>{createdDateContent}</DataText>
          </Flex>
          {/* Creator fees on Solana sometimes vary by asset and we don't currently
          store creator fees at the collection level */}
          {!isSolana(defaultChain.identifier) && (
            <Flex
              className="w-1/2 whitespace-pre"
              data-testid="collection-description-metadata-creator-fee"
            >
              <DataText>{creatorFeeContent}</DataText>
            </Flex>
          )}
          <>
            <Flex
              className="w-1/2 whitespace-pre"
              data-testid="collection-description-metadata-chain"
            >
              <DataText>{collectionChainContent}</DataText>
            </Flex>
          </>
          {categoryContent && (
            <Flex className="w-1/2 whitespace-pre">
              <DataText>{categoryContent}</DataText>
            </Flex>
          )}
        </Flex>
      </Media>
      <Media greaterThanOrEqual="md">
        <Text>{largeContent}</Text>
      </Media>
      <Media lessThan="md">
        <Text size="small">{largeContent}</Text>
      </Media>
    </>
  )

  if (isMerchandisingEnabled) {
    return (
      <Text.Body className={classNames("text-primary", className)} responsive>
        {content}
      </Text.Body>
    )
  }

  return (
    <Block>
      <Media greaterThanOrEqual="sm">
        <Text.Body
          className={classNames("text-primary", className)}
          size="medium"
        >
          {textContent}
        </Text.Body>
      </Media>
      <Media lessThan="sm">
        <Text.Body
          className={classNames("text-primary", className)}
          size="small"
        >
          {textContent}
        </Text.Body>
      </Media>
    </Block>
  )
}

const DataText = ({
  children,
  isBolded = false,
  className,
}: {
  children: ReactNode
  isBolded?: boolean
  className?: string
}) => {
  const isMerchandisingEnabled = useIsMerchandisingViewEnabled()
  if (isMerchandisingEnabled) {
    return (
      <InlineFlex>
        <Text.Body
          className={className}
          responsive
          size="medium"
          weight={isBolded ? "semibold" : "regular"}
        >
          {children}
        </Text.Body>
      </InlineFlex>
    )
  }
  return (
    <InlineFlex>
      <Media lessThan="md">
        <Text.Body
          className={className}
          size="small"
          weight={isBolded ? "semibold" : "regular"}
        >
          {children}
        </Text.Body>
      </Media>
      <Media greaterThanOrEqual="md">
        <Text.Body
          className={className}
          size="medium"
          weight={isBolded ? "semibold" : "regular"}
        >
          {children}
        </Text.Body>
      </Media>
    </InlineFlex>
  )
}
