import React, { useMemo } from "react"
import { Flex, Icon, Separator, Text, classNames } from "@opensea/ui-kit"
import { ClassValue } from "clsx"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { CollectionWatchlistButton } from "@/components/collections/CollectionWatchlistButton.react"
import { IS_SERVER } from "@/constants/environment"
import { useTheme } from "@/design-system/Context"
import { Dropdown } from "@/design-system/Dropdown"
import { IconButton } from "@/design-system/IconButton"
import { Tooltip } from "@/design-system/Tooltip"
import { useIsEditable } from "@/features/collections/hooks/useIsEditable"
import { ReportModal } from "@/features/report/components/ReportModal"
import { trackSubmitCollectionReport } from "@/features/report/utils/analytics"
import { useVerifiedOwnershipTitle } from "@/hooks/useCopy"
import { useIsSSFEnabled } from "@/hooks/useFlag"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionBannerActionBar_data$key } from "@/lib/graphql/__generated__/CollectionBannerActionBar_data.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import {
  getCollectionAssetCreateUrl,
  getCollectionEditUrl,
  getCollectionRoyaltiesUrl,
} from "@/lib/helpers/collection"
import { getSocialIcon, IconName } from "@/lib/helpers/icons"
import { getTwitterItem, getInstagramItem } from "@/lib/helpers/socialUrls"

type CollectionActionBarV2Props = {
  dataKey: CollectionBannerActionBar_data$key | null
  className?: ClassValue
}

type SocialItem = {
  url?: string | null
  icon: JSX.Element
  tooltip: React.ReactNode
}

export const CollectionBannerActionBar = ({
  dataKey,
  className,
}: CollectionActionBarV2Props) => {
  const t = useTranslate("collection")
  const verifiedOwnershipTitle = useVerifiedOwnershipTitle()
  const {
    isOpen: reportModalOpen,
    close: closeReportModal,
    open: openReportModal,
  } = useIsOpen()
  const data = useFragment(
    graphql`
      fragment CollectionBannerActionBar_data on CollectionType {
        relayId
        slug
        representativeAsset {
          assetContract {
            openseaVersion
          }
        }

        assetContracts(first: 2) {
          edges {
            node {
              blockExplorerLink
              chainData {
                blockExplorer {
                  name
                  identifier
                }
              }
            }
          }
        }
        discordUrl
        externalUrl
        instagramUsername
        mediumUsername
        telegramUrl
        twitterUsername
        connectedInstagramUsername
        connectedTwitterUsername
        ...collection_url
        ...CollectionWatchlistButton_data
      }
    `,
    dataKey,
  )

  const isEditable = useIsEditable({ slug: data?.slug })
  const assetContracts = data ? getNodes(data.assetContracts) : null
  const isSSFEnabled = useIsSSFEnabled()

  // check representativeAsset for openseaVersion to see if collection is SSF
  // if there are no items in the collection, we check that there is no assetContracts tied to the collection because collections can have an asset contract but no items minted yet
  const isMintable = Boolean(
    data?.representativeAsset
      ? data.representativeAsset.assetContract.openseaVersion
      : assetContracts?.length === 0,
  )
  const contract = assetContracts?.length === 1 ? assetContracts[0] : undefined

  const iconClassName = classNames(
    "fill-current text-primary hover:opacity-70 active:opacity-60",
    className,
  )

  const visibleIcons: SocialItem[] = useMemo(() => {
    if (!data) {
      return []
    }
    const items: SocialItem[] = [
      {
        url: data.externalUrl,
        icon: getSocialIcon({
          height: 24,
          name: "website",
          className: iconClassName,
        }),
        tooltip: t("social.tooltip.website", "Website"),
      },
    ]

    const twitterItem = getTwitterItem({
      connectedTwitterUsername: data.connectedTwitterUsername,
      twitterUsername: data.twitterUsername,
      getIcon: () =>
        getSocialIcon({
          height: 24,
          name: "twitter",
          className: iconClassName,
        }),
      extraTooltipText: () => (
        <StyledText weight="regular">{verifiedOwnershipTitle}</StyledText>
      ),
    })

    if (twitterItem) {
      items.push(twitterItem)
    }

    return items.filter(item => item.url)
  }, [data, t, verifiedOwnershipTitle, iconClassName])

  const { theme } = useTheme()
  const fill = theme === "light" ? "charcoal" : "white"

  const moreSocialOptions: SocialItem[] = useMemo(() => {
    if (!data) {
      return []
    }
    const items: SocialItem[] = [
      {
        url: data.discordUrl,
        icon: getSocialIcon({ name: "discord", fill, height: 24 }),
        tooltip: t("social.tooltip.discord", "Discord"),
      },
      {
        url:
          data.mediumUsername &&
          `https://www.medium.com/${data.mediumUsername}`,
        icon: getSocialIcon({ name: "medium", fill, height: 24 }),
        tooltip: t("social.tooltip.medium", "Medium"),
      },
      {
        url: data.telegramUrl,
        icon: getSocialIcon({ name: "telegram", fill, height: 24 }),
        tooltip: t("social.tooltip.telegram", "Telegram"),
      },
    ]

    const instagramItem = getInstagramItem({
      connectedInstagramUsername: data.connectedInstagramUsername,
      instagramUsername: data.instagramUsername,
      getIcon: () => getSocialIcon({ name: "instagram", fill, height: 24 }),
      extraTooltipText: () => (
        <StyledText weight="regular">{verifiedOwnershipTitle}</StyledText>
      ),
    })

    if (instagramItem) {
      items.push(instagramItem)
    }

    if (contract) {
      const icon: IconName = contract.chainData.blockExplorer.identifier

      // If we move this conditional item up top to avoid using unshift,
      // the typing becomes more complicated
      items.unshift({
        url: contract.blockExplorerLink,
        icon: getSocialIcon({
          height: 24,
          name: icon,
          fill,
        }),
        tooltip: t("actionBar.viewOn", "View on {{blockExplorer}}", {
          blockExplorer: contract.chainData.blockExplorer.name,
        }),
      })
    }

    return items.filter(item => item.url)
  }, [contract, data, fill, t, verifiedOwnershipTitle])

  if (!data) {
    return null
  }

  return (
    <>
      <Flex className="h-full items-center justify-center gap-5">
        {visibleIcons.map(
          ({ url, icon, tooltip }) =>
            url && (
              <IconButton className="h-6 w-6" href={url} key={url}>
                <Tooltip content={tooltip}>{icon}</Tooltip>
              </IconButton>
            ),
        )}
        <Dropdown
          appendTo={IS_SERVER ? undefined : document.body}
          content={({ List, Item, close }) => (
            <List>
              {moreSocialOptions.map(({ url, icon, tooltip }) => (
                <Item href={url ?? undefined} key={url}>
                  <Item.Avatar>{icon}</Item.Avatar>
                  <Item.Content>
                    <Item.Title>{tooltip}</Item.Title>
                  </Item.Content>
                </Item>
              ))}
              <Separator className="my-2" />
              {isEditable && (
                <>
                  <Item href={getCollectionEditUrl(data)}>
                    <Item.Avatar icon="edit" />
                    <Item.Content>
                      <Item.Title>
                        {t("actionBar.editCollection", "Edit collection")}
                      </Item.Title>
                    </Item.Content>
                  </Item>
                  <Item href={getCollectionRoyaltiesUrl(data)}>
                    <Item.Avatar icon="attach_money" />
                    <Item.Content>
                      <Item.Title>
                        {t("actionBar.viewEarnings", "View earnings")}
                      </Item.Title>
                    </Item.Content>
                  </Item>
                  {isSSFEnabled && isMintable && (
                    <Item href={getCollectionAssetCreateUrl(data)}>
                      <Item.Avatar icon="add" />
                      <Item.Content>
                        <Item.Title>
                          {t("actionBar.addItem", "Add item")}
                        </Item.Title>
                      </Item.Content>
                    </Item>
                  )}
                </>
              )}
              <CollectionWatchlistButton
                className="!rounded-xl"
                data={data}
                slug={data.slug}
                variant="option"
              />
              <Item
                onClick={() => {
                  openReportModal()
                  close()
                }}
              >
                <Item.Avatar icon="flag" />
                <Item.Content>
                  <Item.Title>{t("actionBar.report", "Report")}</Item.Title>
                </Item.Content>
              </Item>
            </List>
          )}
          hideOnScroll
          placement="bottom-start"
        >
          <IconButton
            aria-label={t("actionBar.moreDropdownLabel", "More dropdown")}
            className="h-6 w-6"
            onClick={() => null}
          >
            <Icon className={iconClassName} value="more_horiz" />
          </IconButton>
        </Dropdown>
      </Flex>
      <ReportModal
        isOpen={reportModalOpen}
        subject={{ collection: data.relayId }}
        onClose={closeReportModal}
        onSubmit={({ additionalComments, originalCreatorUrl, reason }) => {
          trackSubmitCollectionReport({
            slug: data.slug,
            additionalComments,
            originalCreatorUrl,
            reason,
          })
        }}
      />
    </>
  )
}

const StyledText = styled(Text.Body).attrs({
  size: "small",
})`
  // required to override tippy styles
  &&& {
    color: ${props => props.theme.colors.text.secondary};
  }
`
