import React from "react"
import { graphql, useFragment } from "react-relay"
import { NewFeatureCalloutDot } from "@/components/common/NewFeatureCalloutDot"
import {
  StickyTabNavigation,
  TabNavigation,
} from "@/design-system/TabNavigation"
import { StyledTabItemText } from "@/design-system/TabNavigation/components/TabItem/TabItem.react"
import { TabNavigationProps } from "@/design-system/TabNavigation/types"
import {
  useIsMerchandisingViewEnabled,
  useIsMerchandisingGTMEnabled,
  useDropCollectionSlugRewrite,
} from "@/hooks/useFlag"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionTabs_collection$key } from "@/lib/graphql/__generated__/CollectionTabs_collection.graphql"
import { useIsAnalyticsTabEnabled } from "./hooks/useIsAnalyticsTabEnabled"
import { useIsOffersTabEnabled } from "./hooks/useIsOffersTabEnabled"
import { useIsOverviewTabEnabled } from "./hooks/useIsOverviewTabEnabled"

export type CollectionTab =
  | "overview"
  | "items"
  | "offers"
  | "analytics"
  | "activity"

const useCollectionTab = (slug: string) => {
  const t = useTranslate("collections")
  const router = useRouter()
  const isCollectionPagePreview = router.asPath.endsWith("/preview")
  const merchandisingGTMEnabled = useIsMerchandisingGTMEnabled()
  const slugRewrite = useDropCollectionSlugRewrite()?.[slug]
  const baseUrlOverride = slugRewrite && `/collection/${slugRewrite}`

  return [
    {
      id: "overview",
      label: t("collectionTab.label.overview", "Overview"),
      renderLabel: (({ label, active }) => {
        return (
          <div className="flex items-center">
            {merchandisingGTMEnabled && !active && !isCollectionPagePreview && (
              <NewFeatureCalloutDot
                animateIn
                className="mr-2"
                feature="merchandising-overview-tab"
              />
            )}
            <StyledTabItemText
              $active={active}
              responsive
              size="medium"
              weight="semibold"
            >
              {label}
            </StyledTabItemText>
          </div>
        )
      }) as TabNavigationProps["renderLabel"],
      onClick: () => {
        NewFeatureCalloutDot.markFeatureSeen("merchandising-overview-tab")
      },
      path: `/overview?${router.queryParamsString}`,
      disabled: isCollectionPagePreview,
    },
    {
      id: "items",
      label: t("collectionTab.label.items", "Items"),
      disabled: isCollectionPagePreview,
      urlOverride: baseUrlOverride,
    },
    {
      id: "offers",
      label: t("collectionTab.label.offers", "Offers"),
      path: `/offers?${router.queryParamsString}`,
      disabled: isCollectionPagePreview,
      urlOverride: baseUrlOverride,
    },
    {
      id: "analytics",
      label: t("collectionTab.label.analytics", "Analytics"),
      path: `/analytics?${router.queryParamsString}`,
      disabled: isCollectionPagePreview,
      urlOverride: baseUrlOverride,
    },
    {
      id: "activity",
      label: t("collectionTab.label.activity", "Activity"),
      path: `/activity?${router.queryParamsString}`,
      disabled: isCollectionPagePreview,
      urlOverride: baseUrlOverride,
    },
  ] as const
}

type CollectionTabsProps = {
  dataKey: CollectionTabs_collection$key | null
  slug: string
  currentTab?: CollectionTab
  isLoading: boolean
  disableSticky?: boolean
}

export function CollectionTabs({
  dataKey,
  slug,
  currentTab,
  isLoading,
  disableSticky,
}: CollectionTabsProps) {
  const data = useFragment(
    graphql`
      fragment CollectionTabs_collection on CollectionType {
        ...useIsAnalyticsTabEnabled_collection
        ...useIsOffersTabEnabled_collection
        ...useIsOverviewTabEnabled_collection
      }
    `,
    dataKey,
  )

  const collectionTabs = useCollectionTab(slug)

  const isAnalyticsTabEnabled = useIsAnalyticsTabEnabled(data)
  const isOffersTabEnabled = useIsOffersTabEnabled(data)
  const isOverviewTabEnabled = useIsOverviewTabEnabled(data)

  // Hide merchandising, analytics, and offers tabs by default
  const filteredCollectionTabs = collectionTabs.filter(tab => {
    switch (tab.id) {
      case "analytics":
        return isAnalyticsTabEnabled
      case "offers":
        return isOffersTabEnabled
      case "overview":
        return isOverviewTabEnabled
      default:
        return true
    }
  })

  const props = {
    baseUrl: `/collection/${slug}`,
    currentTab,
    isLoading,
    tabs: filteredCollectionTabs,
  }

  const isMerchandisingEnabled = useIsMerchandisingViewEnabled()

  return disableSticky ? (
    <TabNavigation responsive={isMerchandisingEnabled} {...props} />
  ) : (
    <StickyTabNavigation responsive={isMerchandisingEnabled} {...props} />
  )
}
