import React from "react"
import { useIsLessThanXXl, Text } from "@opensea/ui-kit"
import { verificationStatusHasBadge } from "@/lib/helpers/verification"
import type { VerificationStatus } from "@/lib/helpers/verification"
import { VerificationStatusBadge } from "../VerificationStatusBadge"

type CollectionHeroBannerTitleProps = {
  title: string
  verificationStatus?: VerificationStatus
}

export const CollectionHeroBannerTitle = ({
  title,
  verificationStatus,
}: CollectionHeroBannerTitleProps) => {
  const isLessThanXXl = useIsLessThanXXl()
  const badge = verificationStatus &&
    verificationStatusHasBadge(verificationStatus) && (
      <VerificationStatusBadge verificationStatus={verificationStatus} />
    )
  const words = title.split(" ")
  const lastWord = words.pop()
  const remainingText = words.join(" ")
  const nameAndBadge = (
    <h1>
      {remainingText}&nbsp;
      <span className="whitespace-nowrap">
        {lastWord} {badge}
      </span>
    </h1>
  )

  return (
    <Text.Heading
      asChild
      className="text-white"
      size={isLessThanXXl ? "small" : "medium"}
    >
      {nameAndBadge}
    </Text.Heading>
  )
}
