import React from "react"
import { ClassValue } from "clsx"
import { useFragment } from "react-relay"
import { useIsMounted } from "@/hooks/useIsMounted"
import { CollectionHeroStats_data$key } from "@/lib/graphql/__generated__/CollectionHeroStats_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { CollectionStatsInner } from "./components/CollectionStatsInner"
import { CollectionStatsSkeleton } from "./components/CollectionStatsSkeleton"

type CollectionHeroStatsProps = {
  dataKey: CollectionHeroStats_data$key | null
  disablePolling?: boolean
  labelClassName?: ClassValue
  numberClassName?: ClassValue
}

export const CollectionHeroStats = ({
  dataKey,
  disablePolling,
  labelClassName,
  numberClassName,
}: CollectionHeroStatsProps) => {
  const isMounted = useIsMounted()
  const data = useFragment(
    graphql`
      fragment CollectionHeroStats_data on CollectionType {
        slug
        ...CollectionStatsInner_data
      }
    `,
    dataKey,
  )

  if (!isMounted || !data) {
    return <CollectionStatsSkeleton />
  }

  return (
    <CollectionStatsInner
      dataKey={data}
      disablePolling={disablePolling}
      labelClassName={labelClassName}
      numberClassName={numberClassName}
      slug={data.slug}
    />
  )
}
