/**
 * @generated SignedSource<<50b10fb85014551f4e51b7da21c206a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Config = "AFFILIATE" | "AFFILIATE_BLACKLISTED" | "AFFILIATE_REQUESTED" | "EMPLOYEE" | "MODERATOR" | "PARTNER" | "STAFF" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountLinkV2_account$data = {
  readonly address: string;
  readonly config: Config | null;
  readonly displayName: string | null;
  readonly isCompromised: boolean;
  readonly user: {
    readonly publicUsername: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileImage_data" | "accounts_url" | "wallet_accountKey">;
  readonly " $fragmentType": "AccountLinkV2_account";
};
export type AccountLinkV2_account$key = {
  readonly " $data"?: AccountLinkV2_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountLinkV2_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "UserType",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicUsername",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountLinkV2_account",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "config",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCompromised",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileImage_data"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "wallet_accountKey",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "accounts_url",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};
})();

(node as any).hash = "ba753d9a826dae6b4773e64adc34fc67";

export default node;
