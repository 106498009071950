import React, { useState, useEffect } from "react"
import { Flex } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { Link } from "@/components/common/Link"
import { StarIcon } from "@/components/svgs/StarIcon.react"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context/ThemeContext"
import { IconButton } from "@/design-system/IconButton"
import { Item } from "@/design-system/Item"
import { Tooltip } from "@/design-system/Tooltip"
import { WATCHLIST_PATH } from "@/features/rankings/constants"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackAddToWatchlist,
  trackRemoveFromWatchlist,
} from "@/lib/analytics/events/watchlistEvents"
import { CollectionWatchlistButton_data$key } from "@/lib/graphql/__generated__/CollectionWatchlistButton_data.graphql"
import { CollectionWatchlistButtonIsWatchingQuery } from "@/lib/graphql/__generated__/CollectionWatchlistButtonIsWatchingQuery.graphql"
import { CollectionWatchlistButtonMutation } from "@/lib/graphql/__generated__/CollectionWatchlistButtonMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { HUES } from "@/styles/themes"

type Props = {
  data: CollectionWatchlistButton_data$key | null
  slug: string
  variant?: "button" | "option"
  className?: string
}

export const CollectionWatchlistButton = ({
  data: dataKey,
  slug,
  variant = "button",
  className,
}: Props) => {
  const t = useTranslate("components")
  const { theme } = useTheme()
  const { handleUnconnectedWallet } = useWalletModal()

  const [isCurrentlyWatching, setIsCurrentlyWatching] = useState(false)

  const data = useFragment(
    graphql`
      fragment CollectionWatchlistButton_data on CollectionType {
        relayId
      }
    `,
    dataKey,
  )

  const [response] =
    useNoSuspenseLazyLoadQuery<CollectionWatchlistButtonIsWatchingQuery>(
      graphql`
        query CollectionWatchlistButtonIsWatchingQuery(
          $collection: CollectionSlug!
        ) {
          collection(collection: $collection) {
            isWatching
          }
        }
      `,
      {
        collection: slug,
      },
    )

  useEffect(() => {
    setIsCurrentlyWatching(!!response?.collection?.isWatching)
  }, [response?.collection?.isWatching])

  const { mutate } = useGraphQL()
  const { attempt, showSuccessMessage } = useToasts()

  const displayText = isCurrentlyWatching
    ? t("collections.watchlistButton.watching", "Watching")
    : t("collections.watchlistButton.add", "Add to watchlist")

  const toggleWatchlistAuthenticated = () =>
    attempt(async () => {
      const { collections } = await mutate<CollectionWatchlistButtonMutation>(
        graphql`
          mutation CollectionWatchlistButtonMutation(
            $collectionId: CollectionRelayID!
            $isWatching: Boolean!
          ) {
            collections {
              updateWatchlist(
                collection: $collectionId
                isWatching: $isWatching
              )
            }
          }
        `,
        {
          collectionId: data?.relayId ?? "",
          isWatching: !isCurrentlyWatching,
        },
        {
          shouldAuthenticate: true,
          updater: store => {
            if (data?.relayId) {
              const collection = store.get(data.relayId)
              if (collection) {
                collection.setValue(!isCurrentlyWatching, "isWatching")
              }
            }
          },
        },
      )

      if (collections.updateWatchlist) {
        if (!isCurrentlyWatching) {
          trackAddToWatchlist({ collectionSlug: slug })
        } else {
          trackRemoveFromWatchlist({ collectionSlug: slug })
        }

        showSuccessMessage(
          <Flex>
            {isCurrentlyWatching
              ? t(
                  "collections.watchlistButton.removeSuccess",
                  "Removed from watchlist.",
                )
              : t(
                  "collections.watchlistButton.addSuccess",
                  "Added to watchlist.",
                )}
            <Block marginLeft="8px">
              <Link href={WATCHLIST_PATH}>
                {t("collections.watchlistButton.cta", "View")}
              </Link>
            </Block>
          </Flex>,
        )
        setIsCurrentlyWatching(!isCurrentlyWatching)
      }
    })

  const toggleWatchlist = async () => {
    handleUnconnectedWallet(toggleWatchlistAuthenticated)
  }
  const starIconColor = theme === "light" ? HUES.charcoal : HUES.fog

  if (variant === "option") {
    return (
      <Item
        className={className}
        data-testid="collection-watchlist-button"
        onClick={toggleWatchlist}
      >
        <Flex className="mr-4">
          <StarIcon
            active={isCurrentlyWatching}
            color={starIconColor}
            height={24}
            width={24}
          />
        </Flex>
        <Item.Content>
          <Item.Title>{displayText}</Item.Title>
        </Item.Content>
      </Item>
    )
  }

  return (
    <Tooltip
      content={
        isCurrentlyWatching
          ? t("collections.watchlistButton.watchingTooltip", "Watching")
          : t("collections.watchlistButton.addTooltip", "Add to watchlist")
      }
      hideOnClick
    >
      <IconButton
        aria-label={
          isCurrentlyWatching
            ? t("collections.watchlistButton.watchingLabel", "Watching")
            : t("collections.watchlistButton.addLabel", "Add to watchlist")
        }
        data-testid="phoenix-watchlist-button"
        onClick={toggleWatchlist}
      >
        <StarIcon
          active={isCurrentlyWatching}
          color={starIconColor}
          height={20}
          width={20}
        />
      </IconButton>
    </Tooltip>
  )
}
