import React, { useMemo } from "react"
import { classNames, useIsLessThanMd } from "@opensea/ui-kit"
import { Image } from "@/design-system/Image"

type HeroMedia = {
  mediaType?: string
  thumbnailUrl?: string
  playbackId?: string
  videoUrl?: string
  imageUrl?: string
}

type CollectionHeroBannerMediaProps = {
  className?: string
  desktopHeroMedia?: HeroMedia
  mobileHeroMedia?: HeroMedia
  legacyBannerImageUrl?: string | null
  profileImageUrl?: string | null
  showOverlay?: boolean
}

export const CollectionHeroBannerMedia = ({
  className,
  desktopHeroMedia,
  mobileHeroMedia,
  legacyBannerImageUrl,
  profileImageUrl,
  showOverlay,
}: CollectionHeroBannerMediaProps) => {
  const isMobile = useIsLessThanMd()
  const innerComponent = useMemo(() => {
    if (desktopHeroMedia || mobileHeroMedia) {
      const media =
        (isMobile ? mobileHeroMedia : desktopHeroMedia) ?? desktopHeroMedia
      return (
        <>
          {media?.mediaType === "IMAGE" && media.imageUrl ? (
            <Image
              alt=""
              layout="fill"
              objectFit="cover"
              src={media.imageUrl}
            />
          ) : (
            <div className="overflow-hidden">
              {media?.mediaType === "VIDEO" ? (
                <video
                  autoPlay
                  className="absolute left-1/2 top-1/2 h-full min-w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                  controls={false}
                  loop
                  muted
                  playsInline
                >
                  <source src={media.videoUrl} type="video/mp4" />
                </video>
              ) : (
                <></>
              )}
            </div>
          )}
          <div
            className={classNames(
              "absolute inset-0 z-10 h-full w-full transition-colors duration-200 ease-in-out",
              "dark bg-transparent bg-gradient-to-b from-[rgb(var(--color-base-1)/0.4)] via-[rgb(var(--color-base-1)/0)] via-35% to-[rgb(var(--color-base-1)/0.8)] dark:to-[rgb(var(--color-base-1)/1)]",
              {
                "bg-black/80": showOverlay,
              },
            )}
          />
        </>
      )
    }

    if (legacyBannerImageUrl) {
      return (
        <>
          <Image
            alt=""
            layout="fill"
            objectFit="cover"
            src={legacyBannerImageUrl}
          />
          <div
            className={classNames(
              "absolute inset-0 z-0 h-full w-full",
              "dark bg-transparent bg-gradient-to-b from-[rgb(var(--color-base-1)/0.4)] to-[rgb(var(--color-base-1)/0.8)] dark:to-[rgb(var(--color-base-1))]",
            )}
          />
        </>
      )
    }

    if (profileImageUrl) {
      return (
        <>
          <Image alt="" layout="fill" objectFit="cover" src={profileImageUrl} />
          <div className="absolute inset-0 z-0 h-full w-full backdrop-blur-[200px]" />
          <div className="absolute inset-0 z-0 h-full w-full bg-black/60" />
        </>
      )
    }

    return (
      <div className="dark absolute inset-0 z-10 h-full w-full bg-black/60 transition-colors duration-200 ease-in-out" />
    )
  }, [
    desktopHeroMedia,
    isMobile,
    legacyBannerImageUrl,
    mobileHeroMedia,
    profileImageUrl,
    showOverlay,
  ])

  return (
    <div
      className={classNames("absolute inset-0 z-0 h-full w-full", className)}
    >
      {innerComponent}
    </div>
  )
}
