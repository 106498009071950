/**
 * @generated SignedSource<<7ebb808f8a030421670039dc2b5669a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCollectionListingStatsEventSubscription$variables = {
  slug: string;
};
export type useCollectionListingStatsEventSubscription$data = {
  readonly listingStatsEvent: {
    readonly collection: {
      readonly " $fragmentSpreads": FragmentRefs<"collection_live_listing_stats">;
    };
  };
};
export type useCollectionListingStatsEventSubscription = {
  response: useCollectionListingStatsEventSubscription$data;
  variables: useCollectionListingStatsEventSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionStatsV2Type",
  "kind": "LinkedField",
  "name": "statsV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "floorPrice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usd",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCollectionListingStatsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ListingStatsEvent",
        "kind": "LinkedField",
        "name": "listingStatsEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "collection_live_listing_stats",
                "selections": [
                  (v2/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCollectionListingStatsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ListingStatsEvent",
        "kind": "LinkedField",
        "name": "listingStatsEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "59451a2f79c2bacd50577173538e90a8",
    "id": null,
    "metadata": {},
    "name": "useCollectionListingStatsEventSubscription",
    "operationKind": "subscription",
    "text": "subscription useCollectionListingStatsEventSubscription(\n  $slug: String!\n) {\n  listingStatsEvent(slug: $slug) {\n    collection {\n      ...collection_live_listing_stats\n      id\n    }\n  }\n}\n\nfragment collection_live_listing_stats on CollectionType {\n  statsV2 {\n    floorPrice {\n      unit\n      eth\n      symbol\n      usd\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0177ee7f0656132eeeb47b99da720393";

export default node;
