/**
 * @generated SignedSource<<fc06d9e297edb673387bbabef239b370>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionStatsInnerPollingQuery$variables = {
  collection: string;
};
export type CollectionStatsInnerPollingQuery$data = {
  readonly collection: {
    readonly " $fragmentSpreads": FragmentRefs<"CollectionStatsInnerPoll_data">;
  } | null;
};
export type CollectionStatsInnerPollingQuery = {
  response: CollectionStatsInnerPollingQuery$data;
  variables: CollectionStatsInnerPollingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collection"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionStatsInnerPollingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CollectionStatsInnerPoll_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CollectionStatsInnerPollingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionStatsV2Type",
            "kind": "LinkedField",
            "name": "statsV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalListed",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              }
            ],
            "concreteType": "OrderV2TypeConnection",
            "kind": "LinkedField",
            "name": "collectionOffers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderV2TypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderV2Type",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "perUnitPriceType",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unit",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "symbol",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "collectionOffers(first:1)"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23c28b43d131d54c8ef676b0061b752d",
    "id": null,
    "metadata": {},
    "name": "CollectionStatsInnerPollingQuery",
    "operationKind": "query",
    "text": "query CollectionStatsInnerPollingQuery(\n  $collection: CollectionSlug!\n) {\n  collection(collection: $collection) {\n    ...CollectionStatsInnerPoll_data\n    id\n  }\n}\n\nfragment CollectionStatsInnerPoll_data on CollectionType {\n  ...collection_poll_stats\n}\n\nfragment collection_poll_stats on CollectionType {\n  statsV2 {\n    totalListed\n  }\n  collectionOffers(first: 1) {\n    edges {\n      node {\n        perUnitPriceType {\n          unit\n          symbol\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3476739a3599b88a0393aa170d57509d";

export default node;
