import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { useTranslate } from "@/hooks/useTranslate"

export const CollectionHeroMintEndedIndicator = () => {
  const t = useTranslate("collection")
  return (
    <Flex className="items-center rounded-lg bg-white/25 px-2 py-1.5 backdrop-blur-lg">
      <div className="mr-2 h-2.5 w-2.5 rounded-full bg-white" />
      <Text.Body
        className="dark whitespace-nowrap"
        size="small"
        weight="semibold"
      >
        {t("banner.mintEnded", "Mint ended")}
      </Text.Body>
    </Flex>
  )
}
