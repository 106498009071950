import React, { useState } from "react"
import { UnstyledButton } from "@opensea/ui-kit"
import { useClickAway, useLocalStorage, useMount } from "react-use"
import { Tooltip } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"

export type CalloutDotFeature =
  | "merchandising-overview-tab"
  | "merchandising-page-editor"

export const NewFeatureCalloutDot = ({
  feature,
  className,
  animateIn,
}: {
  feature: CalloutDotFeature
  className?: string
  animateIn?: boolean
}) => {
  const [featureSeen, setFeatureSeen] = useLocalStorage(
    getLocalStorageKey(feature),
    false,
  )
  const t = useTranslate("components")
  const ref = React.useRef<HTMLButtonElement>(null)

  useClickAway(ref, () => {
    if (animateIn) {
      setFeatureSeen(true)
    }
  })
  const [tooltipVisible, setTooltipVisible] = useState(false)
  useMount(() => {
    setTimeout(() => {
      setTooltipVisible(true)
    }, 1000)
  })
  const content = () => {
    switch (feature) {
      case "merchandising-overview-tab":
        return t(
          "featureCalloutDot.merchandisingOverview",
          "Discover more about this collection",
        )
      case "merchandising-page-editor":
        return t(
          "featureCalloutDot.merchandisingPageEditor",
          "Edit your new page here",
        )
    }
  }
  if (featureSeen) {
    return null
  }
  return (
    <UnstyledButton
      className={className}
      ref={ref}
      onClick={() => {
        setFeatureSeen(true)
      }}
    >
      <Tooltip
        content={content()}
        visible={animateIn ? tooltipVisible : undefined}
      >
        <div className="mr-0 h-2 w-2 rounded-full bg-blue-3" />
      </Tooltip>
    </UnstyledButton>
  )
}

const getLocalStorageKey = (feature: CalloutDotFeature) => {
  return `feature-callout-${feature}`
}

NewFeatureCalloutDot.markFeatureSeen = (feature: CalloutDotFeature) => {
  localStorage.setItem(getLocalStorageKey(feature), JSON.stringify(true))
}
