/**
 * @generated SignedSource<<9a582fd80cc6ae7780849e1175fbe978>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BaseCollectionHeroBanner_data$data = {
  readonly dropv2: {
    readonly stages: ReadonlyArray<{
      readonly endTime: string;
      readonly " $fragmentSpreads": FragmentRefs<"StageStartCountdown_stage" | "useDropStages">;
    }>;
  } | null;
  readonly name: string;
  readonly owner: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountLinkV2_account">;
  } | null;
  readonly verificationStatus: VerificationStatus;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionBannerActionBar_data" | "CollectionHeroInfo_data" | "CollectionHeroStats_data" | "collection_url" | "useDropState_data">;
  readonly " $fragmentType": "BaseCollectionHeroBanner_data";
};
export type BaseCollectionHeroBanner_data$key = {
  readonly " $data"?: BaseCollectionHeroBanner_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BaseCollectionHeroBanner_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BaseCollectionHeroBanner_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountLinkV2_account"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useDropStages",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTime",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StageStartCountdown_stage"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionHeroInfo_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionHeroStats_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionBannerActionBar_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDropState_data"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "5ca3425a1a50767c6a10c2070128fc05";

export default node;
