import { graphql, useFragment } from "react-relay"
import { useIsOffersTabEnabled_collection$key } from "@/lib/graphql/__generated__/useIsOffersTabEnabled_collection.graphql"
import { CHAINS_WITH_ETH_NATIVE_PAYMENT_ASSET } from "./useIsAnalyticsTabEnabled"

export const useIsOffersTabEnabled = (
  dataKey: useIsOffersTabEnabled_collection$key | null,
) => {
  const data = useFragment(
    graphql`
      fragment useIsOffersTabEnabled_collection on CollectionType {
        isCollectionOffersEnabled
        defaultChain {
          identifier
        }
      }
    `,
    dataKey,
  )

  if (!data) {
    return false
  }

  return (
    data.isCollectionOffersEnabled &&
    CHAINS_WITH_ETH_NATIVE_PAYMENT_ASSET.includes(data.defaultChain.identifier)
  )
}
