import { useIsHydrated } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { useDropState } from "@/features/primary-drops/hooks/useDropState"
import {
  useIsMerchandisingViewEnabled,
  useIsRedeemModuleEnabled,
} from "@/hooks/useFlag"
import { useRouter } from "@/hooks/useRouter"
import type { useIsOverviewTabEnabled_collection$key } from "@/lib/graphql/__generated__/useIsOverviewTabEnabled_collection.graphql"
import { graphql } from "@/lib/graphql/graphql"

export const useIsOverviewTabEnabled = (
  dataKey: useIsOverviewTabEnabled_collection$key | null,
) => {
  const isMerchandisingFlagEnabled = useIsMerchandisingViewEnabled()
  const isRedeemModuleEnabled = useIsRedeemModuleEnabled()
  const isHydrated = useIsHydrated()
  const router = useRouter()
  const data = useFragment(
    graphql`
      fragment useIsOverviewTabEnabled_collection on CollectionType {
        ...useDropState_data
        redeemableCampaign {
          __typename
        }
        metadata {
          overview {
            modules {
              __typename
            }
          }
        }
        ...useDropState_data
      }
    `,
    dataKey,
  )
  const dropState = useDropState(data)

  if (isRedeemModuleEnabled && data?.redeemableCampaign && isHydrated) {
    return true
  }

  return (
    (isMerchandisingFlagEnabled &&
      (data?.metadata?.overview?.modules?.length || dropState)) ||
    router.pathname.endsWith("/preview")
  )
}
