import React from "react"
import { Flex, Media, Skeleton } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"

export const CollectionStatsSkeleton = () => {
  return (
    <Flex className="flex-col">
      <Block marginTop="8px">
        <Skeleton.Line className="h-9 w-[350px]" />
      </Block>
      <Media greaterThanOrEqual="sm">
        <Block marginTop="8px">
          <Skeleton.Line className="h-[51px] w-[350px]" />
        </Block>
      </Media>
      <Media lessThan="sm">
        <Block marginTop="8px">
          <Skeleton.Line className="h-[51px] w-[350px]" />
        </Block>
        <Block marginTop="8px">
          <Skeleton.Line className="h-[51px] w-[350px]" />
        </Block>
      </Media>
    </Flex>
  )
}
