import React from "react"
import { useFragment } from "react-relay"
import { CollectionDescriptionMetadata } from "@/components/collections/CollectionDescriptionMetadata.react"
import { TextOverflow } from "@/components/common/TextOverflow/"
import { Markdown } from "@/design-system/Markdown"
import { CollectionHeroInfo_data$key } from "@/lib/graphql/__generated__/CollectionHeroInfo_data.graphql"
import { graphql } from "@/lib/graphql/graphql"

type CollectionInfoProps = {
  description?: string | null
  onSeeMore?: () => void
  dataKey: CollectionHeroInfo_data$key | null
  descriptionClassName?: string
  metadataClassName?: string
  maxHeight?: number
}

export const CollectionHeroInfo = ({
  description,
  onSeeMore,
  dataKey,
  descriptionClassName,
  metadataClassName,
  maxHeight,
}: CollectionInfoProps) => {
  const data = useFragment(
    graphql`
      fragment CollectionHeroInfo_data on CollectionType {
        description
        ...CollectionDescriptionMetadata_data
      }
    `,
    dataKey,
  )
  const text = description?.trim() ? description : ""

  if (!data) {
    return null
  }

  return (
    <div>
      <TextOverflow
        className={descriptionClassName}
        inlineSeeMore
        lines={1}
        maxHeight={maxHeight}
        seeMoreClassName="font-semibold hover:opacity-70 active:opacity-60"
        showExpandIcon={false}
        onSeeMore={onSeeMore}
      >
        <Markdown className="text-inherit" responsive supportLineClamp>
          {text}
        </Markdown>
      </TextOverflow>
      <div className="mt-1 inline-block">
        <CollectionDescriptionMetadata
          className={metadataClassName}
          dataKey={data}
          variant="compact"
        />
      </div>
    </div>
  )
}
