/**
 * @generated SignedSource<<8bcac51608c40f2f8b0f391764798b65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCollectionStatsEventSubscription$variables = {
  slug: string;
};
export type useCollectionStatsEventSubscription$data = {
  readonly statsEvent: {
    readonly collection: {
      readonly " $fragmentSpreads": FragmentRefs<"collection_live_stats">;
    };
  };
};
export type useCollectionStatsEventSubscription = {
  response: useCollectionStatsEventSubscription$data;
  variables: useCollectionStatsEventSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionStatsV2Type",
  "kind": "LinkedField",
  "name": "statsV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOwners",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSupply",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "totalVolume",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numOpenCriteriaOffers",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceType",
  "kind": "LinkedField",
  "name": "totalCriteriaOfferVolume",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCollectionStatsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StatsEvent",
        "kind": "LinkedField",
        "name": "statsEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "collection_live_stats",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCollectionStatsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StatsEvent",
        "kind": "LinkedField",
        "name": "statsEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1ed83661a01a2cea04af1d52dd1622a4",
    "id": null,
    "metadata": {},
    "name": "useCollectionStatsEventSubscription",
    "operationKind": "subscription",
    "text": "subscription useCollectionStatsEventSubscription(\n  $slug: String!\n) {\n  statsEvent(slug: $slug) {\n    collection {\n      ...collection_live_stats\n      id\n    }\n  }\n}\n\nfragment collection_live_stats on CollectionType {\n  statsV2 {\n    numOwners\n    totalSupply\n    totalQuantity\n    totalVolume {\n      unit\n      symbol\n    }\n  }\n  numOpenCriteriaOffers\n  totalCriteriaOfferVolume {\n    unit\n    symbol\n  }\n}\n"
  }
};
})();

(node as any).hash = "c9ab5be1e94a32a943f23b7e42a4012c";

export default node;
