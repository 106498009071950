import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { useCollectionStatsEventSubscription } from "@/lib/graphql/__generated__/useCollectionStatsEventSubscription.graphql"

export const useCollectionListingStatsEvent = ({ slug }: { slug: string }) => {
  const config: GraphQLSubscriptionConfig<useCollectionStatsEventSubscription> =
    useMemo(
      () => ({
        variables: { slug },
        subscription: graphql`
          subscription useCollectionListingStatsEventSubscription(
            $slug: String!
          ) {
            listingStatsEvent(slug: $slug) {
              collection {
                ...collection_live_listing_stats
              }
            }
          }
        `,
      }),
      [slug],
    )
  useSubscription<useCollectionStatsEventSubscription>(config)
}
