import React from "react"
import { UnstyledButton, getTheme, Skeleton } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { PAGE_THUMBNAIL_SIZE } from "@/components/layout/AccountOrCollectionPage/constants"
import { Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { Lightbox } from "@/design-system/Lightbox"
import { shouldUseSeadnUrl } from "@/lib/helpers/urls"
import { media } from "@/styles/styleUtils"

type Props = {
  imageUrl?: string | null
  altText: string
}

export const CollectionBannerThumbImage = ({ imageUrl, altText }: Props) => {
  if (!imageUrl) {
    return (
      <Container $imagePresent={false}>
        <Skeleton.Block />
      </Container>
    )
  }

  const isSeadnUrl = shouldUseSeadnUrl(imageUrl)
  const thumbnailSize = isSeadnUrl ? undefined : PAGE_THUMBNAIL_SIZE * 2
  const fullSize = isSeadnUrl ? undefined : 0

  return (
    <Lightbox
      trigger={open => (
        <Container $imagePresent={!!imageUrl} display="inline-flex">
          <TriggerButton onClick={open}>
            <ImageContainer
              alt={altText}
              height={thumbnailSize}
              layout="fill"
              objectFit="cover"
              priority
              sizes={`${PAGE_THUMBNAIL_SIZE}px`}
              src={imageUrl}
              width={thumbnailSize}
            />
          </TriggerButton>
        </Container>
      )}
    >
      <Block className="relative w-full" height="100%">
        <ImageContainer
          alt={altText}
          height={fullSize}
          layout="fill"
          objectFit="cover"
          sizes="100%"
          src={imageUrl}
          width={fullSize}
        />
      </Block>
    </Lightbox>
  )
}

const ImageContainer = styled(Image)`
  border-radius: ${props => props.theme.borderRadius.default};
`

const TriggerButton = styled(UnstyledButton)`
  width: 100%;
  height: 100%;
`

const Container = styled(Block)<{ $imagePresent: boolean }>`
  width: 64px;
  height: 64px;
  flex-basis: 64px;
  overflow: hidden;

  ${media({
    lg: css`
      width: 80px;
      height: 80px;
      flex-basis: 80px;
    `,
    xxl: css`
      width: 100px;
      height: 100px;
      flex-basis: 100px;
    `,
  })}
  border-radius: ${props => props.theme.borderRadius.pill};
  position: relative;
  z-index: 1;
  background: ${props =>
    props.$imagePresent ? "unset" : getTheme("dark").colors.base1};
  ::after {
    overflow: hidden;
    content: "";
    position: absolute;
    border-radius: ${props => props.theme.borderRadius.pill};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid ${getTheme("dark").colors.components.border.level1};
    z-index: 100;
    box-sizing: border-box;
  }
`
