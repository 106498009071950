/**
 * @generated SignedSource<<67c0440ed6c597099fc8ef97b7a2e573>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionStatsInnerPoll_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"collection_poll_stats">;
  readonly " $fragmentType": "CollectionStatsInnerPoll_data";
};
export type CollectionStatsInnerPoll_data$key = {
  readonly " $data"?: CollectionStatsInnerPoll_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionStatsInnerPoll_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionStatsInnerPoll_data",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_poll_stats",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionStatsV2Type",
          "kind": "LinkedField",
          "name": "statsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalListed",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "OrderV2TypeConnection",
          "kind": "LinkedField",
          "name": "collectionOffers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderV2Type",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PriceType",
                      "kind": "LinkedField",
                      "name": "perUnitPriceType",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "unit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "symbol",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "collectionOffers(first:1)"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "dde6ec17a65841de6a3d2b6d6f3575e8";

export default node;
