/**
 * @generated SignedSource<<4f85a8448968daaead3ba7cda0fed3f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionWatchlistButton_data$data = {
  readonly relayId: string;
  readonly " $fragmentType": "CollectionWatchlistButton_data";
};
export type CollectionWatchlistButton_data$key = {
  readonly " $data"?: CollectionWatchlistButton_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionWatchlistButton_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionWatchlistButton_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "810b6ec3fc033a7027ebb3cf01b80dd7";

export default node;
