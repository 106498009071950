/* istanbul ignore file */
import { SelectOption } from "@/design-system/Select"
import { useTranslate } from "@/hooks/useTranslate"
import { CATEGORY_SLUGS } from "../../../constants"
import { Tag } from "./TagsSearchInput"

// Temp hardcode
export const VISIBLE_CATEGORIES = [
  {
    slug: "art",
    name: "Art",
  },
  {
    slug: "gaming",
    name: "Gaming",
  },
  {
    slug: "memberships",
    name: "Memberships",
  },
  {
    slug: "pfps",
    name: "PFPs",
  },
  {
    slug: "photography",
    name: "Photography",
  },
  {
    slug: "music",
    name: "Music",
  },
]

export const useCategoryNameGetter = () => {
  const t = useTranslate("categorization")

  return (categorySlug: string) => {
    switch (categorySlug) {
      case "art": {
        return t("top.category.art", "Art")
      }
      case "gaming": {
        return t("top.category.gaming", "Gaming")
      }
      case "memberships": {
        return t("top.category.memberships", "Memberships")
      }
      case "pfps": {
        return t("top.category.pfps", "PFPs")
      }
      case "photography": {
        return t("top.category.photography", "Photography")
      }
      case "domain-names": {
        return t("top.category.domainNames", "Domain Names")
      }
      case "music": {
        return t("top.category.music", "Music")
      }
      case "sports-collectibles": {
        return t("top.category.sportsCollectibles", "Sports Collectibles")
      }
      case "virtual-worlds": {
        return t("top.category.virtualWorlds", "Virtual Worlds")
      }
      default: {
        return categorySlug
      }
    }
  }
}

export const useTagsNameGetter = (
  category?: "art" | "gaming" | "pfps" | "memberships" | "photography",
) => {
  const t = useTranslate("categorization")

  return (tagSlug: string) => {
    switch (tagSlug) {
      case "calligraphy": {
        return t("tags.calligraphy", "Calligraphy")
      }
      case "latin": {
        return t("tags.latin", "Latin")
      }
      case "fractal": {
        return t("tags.fractal", "Fractal")
      }
      case "landscape": {
        if (category === "art") {
          return t("tags.landscapeArt", "Landscape Art")
        }

        return t("tags.landscape", "Landscape")
      }
      case "music-games": {
        return t("tags.musicGames", "Music games")
      }
      case "christian&gospel": {
        return t("tags.christianAndGospel", "Christian & Gospel")
      }
      case "architecture": {
        return t("tags.architecture", "Architecture")
      }
      case "comics": {
        return t("tags.comics", "Comics")
      }
      case "music": {
        return t("tags.music", "Music")
      }
      case "aerial-photography": {
        return t("tags.aerialPhotography", "Aerial Photography")
      }
      case "portraits": {
        if (category === "pfps") {
          return t("tags.portraitsPfps", "Portraits PFPs")
        }

        return t("tags.portraits", "Portraits")
      }
      case "people": {
        return t("tags.people", "People")
      }
      case "country": {
        return t("tags.country", "Country")
      }
      case "eth": {
        return t("tags.eth", "ETH")
      }
      case "classical": {
        return t("tags.classical", "Classical")
      }
      case "nature": {
        if (category === "photography") {
          return t("tags.naturePhotography", "Nature Photography")
        }

        return t("tags.natureDefault", "Nature")
      }
      case "pixel-art": {
        if (category === "pfps") {
          return t("tags.pixelArtPfps", "Pixel Art PFPs")
        }

        return t("tags.pixelArt", "Pixel Art")
      }
      case "first-person": {
        return t("tags.firstPerson", "First person")
      }
      case "cosplay": {
        return t("tags.cosplay", "Cosplay")
      }
      case "animal-like": {
        if (category === "pfps") {
          return t("tags.animalLikePfps", "Animal PFPs")
        }

        return t("tags.animalLike", "Animal-like")
      }
      case "literature": {
        return t("tags.literature", "Literature")
      }
      case "mmorpg": {
        return t("tags.mmorpg", "MMORPG")
      }
      case "adventure": {
        if (category === "gaming") {
          return t("tags.adventureGaming", "Adventure Games")
        }

        if (category === "photography") {
          return t("tags.adventurePfps", "Adventure Photography")
        }

        return t("tags.adventure", "Adventure")
      }
      case "funk": {
        return t("tags.funk", "Funk")
      }
      case "adoptables": {
        return t("tags.adoptables", "Adoptables")
      }
      case "nude-art": {
        return t("tags.nudeArt", "Nude Art")
      }
      case "alternative": {
        return t("tags.alternative", "Alternative")
      }
      case "rock": {
        return t("tags.rock", "Rock")
      }
      case "instrumental": {
        return t("tags.instrumental", "Instrumental")
      }
      case "composite": {
        return t("tags.composite", "Composite")
      }
      case "survival": {
        return t("tags.survival", "Survival")
      }
      case "astrophotography": {
        return t("tags.astrophotography", "Astrophotography")
      }
      case "real-time": {
        return t("tags.realTime", "Real-time")
      }
      case "dao": {
        if (category === "memberships") {
          return t("tags.daoMemberships", "DAOs")
        }
        return t("tags.dao", "DAO")
      }
      case "art": {
        if (category === "memberships") {
          return t("tags.artMemberships", "Art Memberships")
        }

        return t("tags.art", "Art")
      }
      case "anthro": {
        return t("tags.anthro", "Anthro")
      }
      case "pop": {
        return t("tags.pop", "Pop")
      }
      case "abstract": {
        if (category === "art") {
          return t("tags.abstractArt", "Abstract Art")
        }

        return t("tags.abstract", "Abstract")
      }
      case "music-videos": {
        return t("tags.musicVideos", "Music videos")
      }
      case "party": {
        return t("tags.party", "Party")
      }
      case "fanclubs": {
        return t("tags.fanclubs", "Fanclubs")
      }
      case "generative": {
        if (category === "art") {
          return t("tags.generativeArt", "Generative Art")
        }

        if (category === "pfps") {
          return t("tags.generativePfps", "Generative PFPs")
        }

        return t("tags.generative", "Generative")
      }
      case "photo-manipulation": {
        return t("tags.photoManipulation", "Photo Manipulation")
      }
      case "horror": {
        return t("tags.horror", "Horror")
      }
      case "sculptures": {
        return t("tags.sculptures", "Sculptures")
      }
      case "jazz": {
        return t("tags.jazz", "Jazz")
      }
      case "turn-by-turn": {
        return t("tags.turnByTurn", "Turn by turn")
      }
      case "metal": {
        return t("tags.metal", "Metal")
      }
      case "k-pop": {
        return t("tags.kpop", "K-pop")
      }
      case "ticketing-events": {
        return t("tags.ticketingEvent", "Ticketing / Events")
      }
      case "trading-cards": {
        return t("tags.tradingCards", "Trading Cards")
      }
      case "membership": {
        return t("tags.membership", "Membership")
      }
      case "kinky": {
        return t("tags.kinky", "Kinky")
      }
      case "puzzles": {
        return t("tags.puzzles", "Puzzles")
      }
      case "shooter": {
        return t("tags.shooter", "Shooter")
      }
      case "game-art": {
        return t("tags.gameArt", "Game Art")
      }
      case "virtual-worlds": {
        return t("tags.virtualWorlds", "Virtual Worlds")
      }
      case "hip-hop": {
        return t("tags.hipHop", "Hip-Hop")
      }
      case "moba": {
        return t("tags.moba", "MOBA")
      }
      case "indie": {
        return t("tags.indie", "Indie")
      }
      case "fantasy": {
        return t("tags.fantasy", "Fantasy")
      }
      case "online": {
        return t("tags.online", "Online")
      }
      case "one-of-one": {
        return t("tags.oneOfOne", "One-of-one")
      }
      case "action": {
        if (category === "gaming") {
          return t("tags.actionGaming", "Action Games")
        }

        return t("tags.action", "Action")
      }
      case "third-person": {
        return t("tags.thirdPerson", "Third person")
      }
      case "punk": {
        return t("tags.punk", "Punk")
      }
      case "stems": {
        return t("tags.stems", "Stems")
      }
      case "generative-art": {
        if (category === "art") {
          return t("tags.generativeArtArt", "Generative Art")
        }

        return t("tags.generativeArt", "Generative Art")
      }
      case "fighting": {
        return t("tags.fighting", "Fighting")
      }
      case "wallpaper": {
        return t("tags.wallpaper", "Wallpaper")
      }
      case "soul": {
        return t("tags.soul", "Soul")
      }
      case "macro": {
        return t("tags.macro", "Macro")
      }
      case "fashion": {
        return t("tags.fashion", "Fashion")
      }
      case "gaming": {
        if (category === "pfps") {
          return t("tags.gamingPfps", "Gaming PFPs")
        }

        if (category === "memberships") {
          return t("tags.gamingMemberships", "Gaming Memberships")
        }

        return t("tags.gaming", "Gaming")
      }
      case "still-life": {
        return t("tags.stillLife", "Still Life")
      }
      case "emoji-emoticons": {
        return t("tags.emojiEmoticons", "Emoji and Emoticons")
      }
      case "food-restaurants": {
        return t("tags.foodRestaurants", "Food / Restaurants")
      }
      case "3d": {
        if (category === "art") {
          return t("tags.3dArt", "3D Art")
        }

        if (category === "gaming") {
          return t("tags.3dGaming", "3D Games")
        }

        if (category === "pfps") {
          return t("tags.3dPfps", "3D PFPs")
        }

        return t("tags.3d", "3D")
      }
      case "racing": {
        return t("tags.racing", "Racing")
      }
      case "ambient": {
        return t("tags.ambient", "Ambient")
      }
      case "wildlife": {
        return t("tags.wildlife", "Wildlife")
      }
      case "human-like": {
        if (category === "pfps") {
          return t("tags.humanLikePfps", "Human PFPs")
        }

        return t("tags.humanLike", "Human-like")
      }
      case "pets": {
        return t("tags.pets", "Pets")
      }
      case "sol": {
        return t("tags.sol", "SOL")
      }
      case "on-chain": {
        if (category === "art") {
          return t("tags.onChainArt", "On-chain Art")
        }

        if (category === "pfps") {
          return t("tags.onChainPfps", "On-Chain PFPs")
        }

        return t("tags.onChain", "On chain")
      }
      case "food": {
        return t("tags.food", "Food")
      }
      case "travel": {
        if (category === "photography") {
          return t("tags.travelPhotography", "Travel Photography")
        }

        return t("tags.travel", "Travel")
      }
      case "dance-electronic": {
        return t("tags.danceElectronic", "Dance / Electronic")
      }
      case "simulation": {
        return t("tags.simulation", "Simulation")
      }
      case "drawings-paintings": {
        return t("tags.drawingsPaintings", "Drawings and Paintings")
      }
      case "r&b": {
        return t("tags.rb", "R&B")
      }
      case "blues": {
        return t("tags.blues", "Blues")
      }
      case "events": {
        return t("tags.events", "Events")
      }
      case "irl": {
        return t("tags.irl", "IRL")
      }
      case "role-playing": {
        return t("tags.rolePlaying", "Role-playing")
      }
      case "street": {
        return t("tags.street", "Street")
      }
      case "utility": {
        if (category === "memberships") {
          return t("tags.utilityMemberships", "Memberships")
        }

        return t("tags.utility", "Utility")
      }
      case "science-fiction": {
        return t("tags.scienceFiction", "Science Fiction")
      }
      case "fan-art": {
        return t("tags.fanArt", "Fan Art")
      }
      case "digital-art": {
        if (category === "memberships") {
          return t("tags.digitalArtMemberships", "Art Memberships")
        }

        if (category === "pfps") {
          return t("tags.digitalArtPfps", "Digital Art PFPs")
        }

        return t("tags.digitalArt", "Digital Art")
      }
      case "board": {
        return t("tags.board", "Board")
      }
      case "anime": {
        return t("tags.anime", "Anime")
      }
      case "sports": {
        return t("tags.sports", "Sports")
      }
      case "strategy": {
        if (category === "gaming") {
          return t("tags.strategyGaming", "Strategy Games")
        }

        return t("tags.strategy", "Strategy")
      }
      case "virtual-land": {
        return t("tags.virtualLand", "Virtual land")
      }
      case "football": {
        return t("tags.football", "Football")
      }
      case "hockey": {
        return t("tags.hockey", "Hockey")
      }
      case "basketball": {
        return t("tags.basketball", "Basketball")
      }
      case "baseball": {
        return t("tags.baseball", "Baseball")
      }
      case "nfl": {
        return t("tags.nfl", "NFL")
      }
      case "nhl": {
        return t("tags.nhl", "NHL")
      }
      case "mba": {
        return t("tags.mba", "MBA")
      }
      case "nba": {
        return t("tags.nba", "NBA")
      }
      case "fifa": {
        return t("tags.fifa", "FIFA")
      }
      case "memberships": {
        return t("tags.memberships", "Memberships")
      }
      case "photography": {
        return t("tags.photography", "Photography")
      }
      case "redeemables": {
        return t("tags.redeemables", "Redeemables")
      }
      default: {
        return tagSlug
      }
    }
  }
}

export const useTopLevelCategoryOptions: () => Array<SelectOption> = () => {
  const t = useTranslate("categorization")
  const nameGetter = useCategoryNameGetter()

  return CATEGORY_SLUGS.map(slug => {
    return {
      label: nameGetter(slug),
      value: slug,
      key: slug,
    }
  }).concat({
    label: t("category.noCategory", "No category"),
    value: "none",
    key: "none",
  })
}

export const useDefaultTags: (
  tagSlugs: Array<string>,
) => Array<Tag> = tagSlugs => {
  const nameGetter = useTagsNameGetter()

  return tagSlugs.map(slug => ({
    id: slug,
    label: nameGetter(slug),
  }))
}
