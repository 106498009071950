/**
 * @generated SignedSource<<7983bebde148f86eb11ee5bcc6b68274>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useIsOffersTabEnabled_collection$data = {
  readonly defaultChain: {
    readonly identifier: ChainIdentifier;
  };
  readonly isCollectionOffersEnabled: boolean;
  readonly " $fragmentType": "useIsOffersTabEnabled_collection";
};
export type useIsOffersTabEnabled_collection$key = {
  readonly " $data"?: useIsOffersTabEnabled_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsOffersTabEnabled_collection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsOffersTabEnabled_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCollectionOffersEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "defaultChain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "identifier",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "f4231f2d21661a38b0a4696601554c13";

export default node;
