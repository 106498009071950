import React from "react"
import {
  classNames,
  Flex,
  FlexColumn,
  SpaceBetween,
  Container,
  useIsLessThanLg,
  useIsLessThanXXl,
} from "@opensea/ui-kit"
import { $nav_height } from "@/styles/variables"

// For components that render differently when on base background vs media background
type SegmentedComponent = {
  on: {
    base: React.ReactNode
    media: React.ReactNode
  }
}

export type CollectionHeroBannerLayoutProps = {
  actionBar?: SegmentedComponent
  accountLink?: React.ReactNode
  dropCountdown?: React.ReactNode
  info?: SegmentedComponent
  isPreview?: boolean
  media: React.ReactNode
  thumbnail: React.ReactNode
  title: React.ReactNode
  mintingIndicator?: React.ReactNode
  showLegacyMedia: boolean
  stats?: SegmentedComponent
}

export const CollectionHeroBannerLayout = ({
  actionBar,
  accountLink,
  dropCountdown,
  info,
  isPreview,
  media,
  thumbnail,
  title,
  mintingIndicator,
  showLegacyMedia,
  stats,
}: CollectionHeroBannerLayoutProps) => {
  const isLessThanLg = useIsLessThanLg()
  const isLessThanXXl = useIsLessThanXXl()
  const showDescriptionOverHero = !showLegacyMedia && !isLessThanXXl
  // TODO(samhv) -- refactor it, it's too complicated
  return (
    <>
      <div
        className={classNames("relative w-full overflow-hidden", {
          "h-[340px]": showLegacyMedia,
          "aspect-w-3 aspect-h-4 sm:aspect-w-16 sm:aspect-h-9 lg:aspect-w-8 lg:aspect-h-3 bg-gray-3 dark:bg-base-1":
            !showLegacyMedia,
        })}
      >
        <div
          className="group/banner h-full items-end"
          style={{ paddingTop: $nav_height }}
        >
          {media}
          <Container className="relative z-[1] flex h-full flex-col justify-end pb-6">
            <SpaceBetween className="items-end">
              <FlexColumn className="h-full max-w-[620px] justify-end">
                <Flex className="mb-4 xxl:mb-6">{thumbnail}</Flex>

                <Flex className="items-start">
                  <FlexColumn>
                    {mintingIndicator && (
                      <Flex className="mb-2">{mintingIndicator}</Flex>
                    )}
                    <Flex className="items-center">
                      {title}

                      {!isLessThanXXl && (
                        <div className="ml-4 border-l-[1px] border-white/20 pl-4">
                          {actionBar?.on.media}
                        </div>
                      )}
                    </Flex>
                    {accountLink && (
                      <div className="mt-[2px]">{accountLink}</div>
                    )}
                  </FlexColumn>
                </Flex>
                {showDescriptionOverHero && (
                  <div className="relative mt-6">{info?.on.media}</div>
                )}
              </FlexColumn>
              {isLessThanLg ? (
                <div className="mt-auto h-8 items-center">
                  {actionBar?.on.media}
                </div>
              ) : (
                <div>{dropCountdown || stats?.on.media}</div>
              )}
            </SpaceBetween>
            {isLessThanLg && (
              <Flex
                className={classNames(
                  "mt-4 overflow-x-scroll border-t border-white/20 pt-4",
                  isPreview ? "overflow-hidden" : "overflow-x-scroll",
                )}
              >
                {dropCountdown || stats?.on.media}
              </Flex>
            )}
          </Container>
        </div>
      </div>
      {!showDescriptionOverHero && (
        <div className="mb-2 mt-6">
          <SpaceBetween className="items-start">
            <FlexColumn className="flex-1">
              <Container>
                <div className="relative w-full max-w-[620px]">
                  {info?.on.base}
                </div>
              </Container>
            </FlexColumn>
            <Container className="m-0 w-min">
              {isLessThanXXl && !isLessThanLg && (
                <div>{actionBar?.on.base}</div>
              )}
            </Container>
          </SpaceBetween>
        </div>
      )}
    </>
  )
}
