import { graphql } from "react-relay"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useIsEditableQuery } from "@/lib/graphql/__generated__/useIsEditableQuery.graphql"

export const useIsEditable = ({ slug }: { slug: string | undefined }) => {
  const [response] = useNoSuspenseLazyLoadQuery<useIsEditableQuery>(
    graphql`
      query useIsEditableQuery($collection: CollectionSlug!) {
        collection(collection: $collection) {
          isEditable
        }
      }
    `,
    {
      collection: slug as string,
    },
    { skip: !slug },
  )

  const collection = response?.collection

  return !!collection?.isEditable
}
